import "./VaultItem.scss";
import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { useVault } from "../../../Services/VaultService/VaultService";
import { parseName } from "../../../../../utils/Web3Actions";
import { Chip, Divider, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  getLevelBySession,
  getMultipliedRewardByType,
  LEVEL_COLORS,
} from "../../../../../utils/index";

export const VaultItem: React.FC<any> = ({
  itemData,
  showInformationModal,
}: {
  itemData: any;
  showInformationModal: any;
}) => {
  const {
    stakingProjectData,
    unstakingDocs,
    busyStakingTokenAddress,
    stakingPlans,
  } = useVault();
  const [percent, setPercent] = useState<any>();
  const [stakingPlan, setStakingPlan] = useState<any>(null);
  const [level, setLevel] = useState(1);

  useEffect(() => {
    if (!itemData?.stakingSession) {
      setLevel(1);
      return;
    }
    if (stakingPlan?.maturityLevels) {
      setLevel(
        getLevelBySession(stakingPlan?.maturityLevels, itemData?.stakingSession)
      );
    }
  }, [itemData?.stakingSession, stakingPlan?.maturityLevels]);

  useEffect(() => {
    setStakingPlan(
      stakingPlans?.filter(
        (plan) => plan.AStakingProgramId === itemData.PlanId
      )?.[0]
    );
  }, [itemData.PlanId, stakingPlans]);

  useEffect(() => {
    if (itemData?.staked === true) {
      (() => {
        const start = itemData.created!.getTime();
        const end = itemData.expirationDate!.getTime();
        const now = Date.now();
        const p = 100 - ((now - start) / (end - start)) * 100;
        setPercent(p <= 0 ? 0 : p);
      })();
      const interval = setInterval(() => {
        const start = itemData.created!.getTime();
        const end = itemData.expirationDate!.getTime();
        const now = Date.now();
        const p = 100 - ((now - start) / (end - start)) * 100;

        setPercent(p <= 0 ? 0 : p);
      }, 30000);
      return () => clearInterval(interval);
    }
    return;
  }, []);

  // const getDays = () => {
  //     const now = moment();
  //     const staked = moment(itemData.created);
  //     const daysLeft = selectedVaultData.StakeDurationDays - now.diff(staked, 'days')
  //     return `${daysLeft} days left`
  // }

  // const getPercent = () => {
  //     const now = moment();
  //     const staked = moment(itemData.created);
  //     const daysLeft = selectedVaultData.StakeDurationDays - now.diff(staked, 'days')
  //     return (daysLeft / selectedVaultData.StakeDurationDays) * 100
  // }

  const getNumberFromName = () => {
    return parseName(itemData?.tokenData?.name || "") || "";
  };

  const isUnstaking = () => unstakingDocs.includes(itemData.DocId);
  const isStaking = () =>
    busyStakingTokenAddress?.includes(itemData.mintAddress);

  const getStakingProgram = (StakingProgram: string) => {
    switch (StakingProgram) {
      case "duration":
        return "LOCKED";
      case "unstake":
        return "CAN UNSTAKE";
      case "non-custodial":
        return "NON-CUSTODIAL";
      default:
        break;
    }
  };

  return (
    <>
      {stakingProjectData && itemData && (
        <div className="vault-item-container">
          <div className="image-container">
            <img
              className="image"
              src={itemData?.tokenData?.image}
              alt="Vault"
            />
            <div className="image-text">
              {getNumberFromName()}
              {/* #{itemData?.tokenData?.name?.split("#")?.[1] || ""} */}
            </div>
            {itemData.busyStaking && (
              <>
                <div className="busy-container">
                  <div className="busy-loader"></div>
                </div>
              </>
            )}
          </div>
          {itemData.staked === true ? (
            <div className="staked-container">
              <div className="staked-info-container">
                <div className="staked-info-section">
                  {getStakingProgram(itemData.StakingProgram)}
                </div>
                {stakingPlan?.maturityActive && (
                  <Box sx={{ marginInline: "10%" }}>
                    <Divider
                      sx={{
                        "&::before, &::after": {
                          borderColor: "rgba(255, 255, 255, 0.102)",
                        },
                      }}
                    >
                      <Chip
                        label={`Level ${level}`}
                        variant="outlined"
                        sx={{
                          color: LEVEL_COLORS[level],
                          borderColor: "rgba(255, 255, 255, 0.102)",
                          textTransform: "uppercase",
                        }}
                      />
                    </Divider>
                  </Box>
                )}

                <div className="staked-info-section">
                  <b>
                    {(itemData?.multiplierInfo
                      ? getMultipliedRewardByType(
                          itemData.multiplierInfo?.MultiplierAction,
                          itemData.dailyReward,
                          itemData?.multiplierInfo?.MultiplierDaliyReward
                        )
                      : itemData.dailyReward
                    ).toFixed(4)}{" "}
                    ${itemData.TokenName}
                  </b>{" "}
                  / day
                  {stakingPlan?.maturityActive && (
                    <IconButton
                      aria-label="question"
                      color="inherit"
                      size="small"
                      sx={{ marginLeft: 0.5 }}
                      onClick={() => {
                        if (stakingPlan) {
                          showInformationModal({
                            ...stakingPlan,
                            dailyReward: itemData.dailyReward,
                            baseDailyReward: itemData.baseDailyReward,
                            multiplierInfo: itemData.multiplierInfo,
                            stakingSession: itemData.stakingSession,
                          });
                        }
                      }}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  )}
                </div>
                {itemData.multiplierInfo && (
                  <div className="multiplier-info-section">
                    Multiplier Active
                  </div>
                )}
                <div className="divider" />
                <div className="staked-info-section">
                  <div className="time-left">
                    {isUnstaking() ? (
                      <Completionist />
                    ) : (
                      <Countdown
                        date={itemData.expirationDate}
                        renderer={renderer}
                      />
                    )}
                  </div>
                </div>
              </div>
              {percent > 0 && <progress value={percent} max="100"></progress>}
            </div>
          ) : (
            <>
              {isStaking() && (
                <div className="staked-container">
                  <div className="time-left">
                    <span style={{ fontSize: "13px" }}>BUSY STAKING</span>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

const Completionist = () => (
  <span style={{ fontSize: "13px" }}>BUSY UNSTAKING</span>
);

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
  if (completed) {
    // Render a complete state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <div>
        Time left
        <div className="items-container">
          <div className="item-container">
            <div className="item-value">{days}</div>
            <div className="item-title">D</div>
          </div>
          <div className="divider"></div>
          <div className="item-container">
            <div className="item-value">{hours}</div>
            <div className="item-title">H</div>
          </div>
          <div className="divider"></div>
          <div className="item-container">
            <div className="item-value">{minutes}</div>
            <div className="item-title">M</div>
          </div>
        </div>
      </div>
    );
  }
};
