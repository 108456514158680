import "./Stats.scss";

export const Stats = () => {
  return (
    <div className="stats-container">
      <div className="stats-section">
        <div className="stats-content-container">
          <div className="value">$30M+</div>
          <div className="description">under management</div>
        </div>
      </div>
      <div className="stats-section">
        <div className="stats-content-container">
          <div className="value">100k+</div>
          <div className="description">verified users</div>
        </div>
      </div>
      <div className="stats-section">
        <div className="stats-content-container">
          <div className="value">200k</div>
          <div className="description">txs per month</div>
        </div>
      </div>
    </div>
  );
};
