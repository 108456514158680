import React, { useContext, createContext, useEffect, useState } from "react";
import { ReactDimmer } from "react-dimmer";
import "./MultiplierInfoModalService.scss";
import { Spin as Hamburger } from "hamburger-react";
import { AButton } from "../../../ReusableConponants/AButton/AButton";

import CircularProgress from "@mui/material/CircularProgress";
import tickSVG from "../../../../../assets/images/tick.svg";
import { useFirebase } from "../../../Services/FirebaseService/FirebaseService";
import { useWallet } from "@solana/wallet-adapter-react";
import {
  TokenData,
  useVault,
} from "../../../Services/VaultService/VaultService";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import { parseName } from "../../../../../utils/Web3Actions";

const modalContainer: any = {
  width: "100vw",
  height: "100vh",
  position: "fixed",
  zIndex: 50,
  display: "block",
  top: "0px",
  left: "0px",
  overflow: "auto",
};

export type StakeModalData = {
  onStakeSelected?: any;
  disableForm?: any;
};

export const MultiplierInfoModalService = createContext<StakeModalData>({});

export const useMultiplierInfoModal = () => {
  return useContext(MultiplierInfoModalService);
};

export const MultiplierInfoModalProvider = ({ children }: any) => {
  const [dimmer, setDimmer] = useState<boolean>(false);
  const [multiplierDoc, setMultiplierDoc] = useState<any>();

  useEffect(() => {
    document.body.style.overflowY = dimmer ? "hidden" : "auto";
  }, [dimmer]);

  const onBackdropSelected = (action: boolean) => {
    setDimmer(action);
  };

  const onStakeSelected = async (multiplierDoc: any) => {
    setMultiplierDoc(multiplierDoc);
    setDimmer(true);
  };

  const vaule: StakeModalData = {
    onStakeSelected,
  };

  return (
    <MultiplierInfoModalService.Provider value={vaule}>
      {children}
      <ReactDimmer
        zIndex={4}
        opacity={0.8}
        blur={15}
        transition={0.2}
        isOpen={dimmer}
        exitDimmer={onBackdropSelected}
      />
      {dimmer && (
        <div style={modalContainer}>
          <StakeForm
            multiplierDoc={multiplierDoc}
            closeModal={() => setDimmer(false)}
          />
          <div className="close-overlay-btn">
            <Hamburger
              hideOutline={true}
              color="#ffdd02"
              toggled={dimmer}
              toggle={setDimmer}
            />
          </div>
        </div>
      )}
    </MultiplierInfoModalService.Provider>
  );
};

type ItemSelect = {
  token: TokenData;
  selected: boolean;
  hasMultiplier: boolean;
  original: boolean;
};

const StakeForm = ({ closeModal, multiplierDoc }: any) => {
  const [MAX, setMax] = useState(0);
  const { publicKey } = useWallet();
  const {
    stakedItems,
    stakingProjectData: { VaultId, ProjectId },
    loadingUnstaked,
  } = useVault();
  const { firestore, customFirebaseRequest } = useFirebase();
  const [items, setItems] = useState<ItemSelect[]>([]);
  const [supportedCollections, setSupportedCollections] = useState<any>(null);
  const [isBusy, setIsBusy] = useState(false);
  const [multiplierProgramDoc, setMultiplierProgramDoc] = useState<any>();
  const [loadingSupportedCollection, setLoadingSupportedCollection] =
    useState(false);
  const { addToast } = useToasts();

  useEffect(() => {
    if (multiplierDoc) {
      (async () => {
        setLoadingSupportedCollection(true);
        const multiplierProgramDoc = await multiplierDoc
          .data()
          .StakingProgramRef.get();
        const { MaxUsable } = multiplierDoc.data();
        setMax(MaxUsable);
        setMultiplierProgramDoc(multiplierProgramDoc);
        multiplierDoc
          .data()
          .StakingProgramRef.collection(`/AMultiplierEligableCollection`)
          .get()
          .then((res: any) => {
            const supportedTokens = res.docs
              .map((doc: any) => doc.data().address)
              .flat();
            setSupportedCollections(supportedTokens);
            setLoadingSupportedCollection(false);
          })
          .catch((err: any) => {
            console.error("Get supported collection err", err);
            setSupportedCollections([]);
            setLoadingSupportedCollection(false);
          });
      })();
    }
  }, [multiplierDoc]);

  useEffect(() => {
    if (isBusy) return;
    if (loadingSupportedCollection === false) {
      const parseItems = (stakedItems || [])
        .filter(
          (doc) =>
            doc.busyStaking === false &&
            doc.expirationDate!.getTime() > Date.now()
        )
        .filter((doc) => {
          if (supportedCollections) {
            console.log(supportedCollections, doc.mintAddress);

            return supportedCollections!.includes(doc.mintAddress) === true;
          }
          return false;
        })
        .filter((doc) => {
          if (multiplierProgramDoc?.data().FilterOption === "attribute") {
            const { AttributeMap } = multiplierProgramDoc?.data();
            const { attributes } = doc.tokenData;
            if (AttributeMap) {
              for (const attributeKey in AttributeMap) {
                for (const traitKey in AttributeMap[attributeKey]) {
                  console.log(attributeKey, traitKey);

                  if (AttributeMap[attributeKey][traitKey].supported) {
                    const hasTrait = !!(attributes as any[]).find(
                      ({ trait_type, value }: any) =>
                        trait_type === attributeKey && value === traitKey
                    );
                    if (hasTrait) {
                      return true;
                    }
                  }
                  console.log(AttributeMap[attributeKey][traitKey]);
                }
              }
            }
          } else {
            console.log(multiplierProgramDoc?.data().FilterOption);

            return true;
          }
          return false;
        })
        .map((item) => {
          console.log(item.multiplierInfo);
          return {
            token: item,
            original:
              item.multiplierInfo?.AMultiplierStakedTokenRef.id ===
              multiplierDoc.id,
            selected:
              item.multiplierInfo?.AMultiplierStakedTokenRef.id ===
              multiplierDoc.id,
            hasMultiplier: !!(
              item.multiplierInfo &&
              item.multiplierInfo?.AMultiplierStakedTokenRef?.id !==
                multiplierDoc.id
            ),
          };
        })
        .sort((a, b) => (a.hasMultiplier ? 1 : 0) - (b.hasMultiplier ? 1 : 0));
      setItems([...parseItems]);
    } else {
      setItems([]);
    }
  }, [
    supportedCollections,
    loadingSupportedCollection,
    stakedItems,
    multiplierProgramDoc,
    isBusy,
  ]);

  const setItem = (index: number) => {
    if (isBusy === false) {
      const arr: ItemSelect[] = [...items];
      console.log(arr[index]);

      if (arr[index].hasMultiplier == false) {
        arr[index].selected =
          arr[index].selected === true ? false : checkIsMaxed() ? false : true;
        setItems(arr);
      }
    }
  };

  const selectMax = () => {
    if (isBusy === true) return;
    const arr: ItemSelect[] = [...items];
    for (let index = 0; index < arr.length; index++) {
      const { LimitPlan } = multiplierDoc.data();
      arr[index].selected =
        (arr[index].hasMultiplier === false && index < MAX) ||
        LimitPlan === "all";
    }
    setItems(arr);
  };

  const unselectAll = () => {
    if (isBusy === true) return;
    const arr: ItemSelect[] = [...items];
    for (let index = 0; index < arr.length; index++) {
      arr[index].selected = false;
    }
    setItems(arr);
  };

  const getSelected = () =>
    items
      .filter((items) => items.selected)
      .map((item) => item.token.mintAddress);

  const checkIsMaxed = () => {
    const { LimitPlan } = multiplierDoc.data();
    if (LimitPlan === "all") return false;
    return items.filter((i) => i.selected === true).length >= MAX;
  };

  const getNumberFromName = (text: string) => {
    return parseName(text || "") || "";
  };
  // useEffect(() => {
  //   if (items?.length > 0) updateChanges();
  // }, [items]);

  const getHasChangedDocs = () =>
    items.filter(({ selected, original }) => selected !== original).length > 0;

  const updateChanges = async () => {
    setIsBusy(true);
    const changedDocs = items.filter(
      ({ selected, original }) => selected !== original
    );
    if (changedDocs.length === 0) return;
    console.log({
      addStakedTokenDocIds: changedDocs
        .filter(({ selected }) => selected)
        .map(({ token: { DocId } }) => DocId),
      removeStakedTokenDocIds: changedDocs
        .filter(({ selected }) => !selected)
        .map(({ token: { DocId } }) => DocId),
    });

    try {
      const res = await customFirebaseRequest(
        "V7User_handleRequestActivateMultiplier",
        {
          projectId: ProjectId,
          vaultId: VaultId,
          addStakedTokenDocIds: changedDocs
            .filter(({ selected }) => selected)
            .map(({ token: { DocId } }) => DocId),
          removeStakedTokenDocIds: changedDocs
            .filter(({ selected }) => !selected)
            .map(({ token: { DocId } }) => DocId),
          multiplierDocId: multiplierDoc.id,
          walletAddress: publicKey?.toString(),
        }
      );
      addToast("Multiplier changes applied successfully!", {
        autoDismiss: true,
        appearance: "success",
      });
    } catch (err) {
      addToast("Multiplier changes failed apply... please try again", {
        autoDismiss: true,
        appearance: "error",
      });

      console.error("Activete multiplier err", err);
    }
    setIsBusy(false);
  };

  const getBunusText = () => {
    const { MultiplierAction, MultiplierValue } = multiplierDoc.data();
    switch (MultiplierAction) {
      case "multiplication":
        return (
          <>
            <b>Multiplies</b> a Staked NFT's daily rewards by{" "}
            <b>{MultiplierValue}</b>X
          </>
        );
      case "addition":
        return (
          <>
            <b>Adds</b> a bonus of +<b>{MultiplierValue}</b> to a staked NFT's
            daily rewards
          </>
        );
      case "fixed":
        return (
          <>
            <b>Boost</b> the daily reward of staked NFTs to fixed{" "}
            <b>{MultiplierValue}</b> tokens per day
          </>
        );

      default:
        return <></>;
    }
  };
  const getBunusReward = (dailyReward: number) => {
    const { MultiplierAction, MultiplierValue } = multiplierDoc.data();
    switch (MultiplierAction) {
      case "multiplication":
        return dailyReward * MultiplierValue - dailyReward;
      case "addition":
        return MultiplierValue;
      case "fixed":
        return MultiplierValue - dailyReward;
      default:
        return 0;
    }
  };

  if (multiplierProgramDoc && multiplierDoc) {
    const { MaxUsable, LimitPlan, expirationDate } = multiplierDoc.data();
    return (
      <>
        <div className="multipler-activate-container">
          {multiplierProgramDoc && multiplierDoc && (
            <>
              <div className="title">Activate Multiplier</div>
              <div className="select-plan-main-container">
                <div className="multiplier-info-main-container">
                  <img
                    className="multiplier-image"
                    src={multiplierDoc.data().manifest.image}
                    alt="Multiplier NFT"
                  />
                  <div className="multiplier-info-container">
                    <div className="multiplier-name">
                      {multiplierDoc.data().manifest.name}
                    </div>
                    <div className="multiplier-bonus-desc">
                      {getBunusText()}
                    </div>
                  </div>
                </div>
              </div>

              {moment().isAfter(expirationDate.toDate()) && (
                <div className="multiplier-helper">
                  Your multiplier NFT is busy unstaking
                </div>
              )}
              {moment().isBefore(expirationDate.toDate()) && (
                <>
                  <div className="multiplier-helper">
                    Please select the Staked NFTs you would like to apply the
                    multiplier to
                  </div>

                  {items.length > 0 && (
                    <>
                      <div className="acitions">
                        <AButton
                          disabled={isBusy}
                          onClick={selectMax}
                          skin="primary"
                          size="small"
                        >
                          {LimitPlan === "limit" ? (
                            <>Select Max</>
                          ) : (
                            <>Select All</>
                          )}
                        </AButton>
                        <AButton
                          disabled={isBusy}
                          onClick={unselectAll}
                          skin="light"
                          size="small"
                        >
                          <>Unselect All</>
                        </AButton>
                      </div>
                      <div className="explainer">
                        {LimitPlan === "limit" ? (
                          <>You can select up to {MaxUsable} staked NFTs</>
                        ) : (
                          <>You can select all staked NFTs</>
                        )}
                      </div>
                    </>
                  )}
                  {loadingUnstaked && (
                    <div className="vault-items-section-container">
                      <CircularProgress color="inherit" />
                    </div>
                  )}
                  {loadingSupportedCollection && (
                    <CircularProgress color="inherit" />
                  )}

                  {!loadingSupportedCollection &&
                    !loadingUnstaked &&
                    items.length === 0 && (
                      <>
                        <div className="no-items">
                          You don't have any staked NFTs that are allowed to use
                          this multiplier...
                        </div>
                      </>
                    )}

                  <div
                    style={{
                      opacity: isBusy ? 0.6 : 1,
                    }}
                    className="multiplier-form-container"
                  >
                    {items.length > 0 &&
                      items.map((i, index) => {
                        return (
                          <div
                            onClick={() => setItem(index)}
                            className="form-item-container fade-in-ani"
                          >
                            <div className="form-title">
                              {getNumberFromName(i.token.tokenData.name)}
                            </div>
                            {i.hasMultiplier === false && (
                              <div className="form-calculated-bonus">
                                <b>
                                  +{getBunusReward(i!.token!.dailyReward!)} $
                                  {i.token.TokenName}
                                </b>
                              </div>
                            )}
                            {i.hasMultiplier === true && (
                              <div className="form-calculated-bonus">
                                IN USE
                              </div>
                            )}
                            <div className="form-item-inner-container">
                              <div
                                className={
                                  i.selected === true
                                    ? "form-item-selected"
                                    : "form-item"
                                }
                                style={{
                                  opacity:
                                    i.selected === false && checkIsMaxed()
                                      ? 0.3
                                      : i.hasMultiplier
                                      ? 0.3
                                      : 1,
                                  background: `url('${i.token.tokenData.image}') center center`,
                                  backgroundSize: "contain",
                                }}
                              >
                                {(i.selected === true || i.hasMultiplier) && (
                                  <div className="selected-container noselect">
                                    <img
                                      className="tick"
                                      src={tickSVG}
                                      alt=""
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  {getHasChangedDocs() && (
                    <div className="fade-in-ani">
                      {isBusy ? (
                        <div style={{ marginTop: "20px" }}>
                          <div className="minting-button-loader"></div>
                        </div>
                      ) : (
                        <AButton
                          style={{ marginTop: "30px" }}
                          disabled={isBusy}
                          onClick={updateChanges}
                        >
                          UPDATE
                        </AButton>
                      )}
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </>
    );
  }
  return <></>;
};
