
import { useWallet } from "@solana/wallet-adapter-react";
import { PublicKey, Transaction } from "@solana/web3.js";
import { createContext, useContext, useEffect, useState } from "react";

export type WalletWrapperServicePayload = {
    publicKey?: PublicKey | undefined,
    signTransaction?: ((transaction: Transaction) => Promise<Transaction>) | undefined
}

const WalletWrapperContext = createContext<WalletWrapperServicePayload>({})

export function useWalletWrapperService() {
    return useContext(WalletWrapperContext)
}

let w: any = window;

export const WalletWrapperService: any = ({ children }: any) => {
    const { publicKey, signTransaction } = useWallet();
    const [data, setData] = useState<WalletWrapperServicePayload>({});
    useEffect(() => {
        if (publicKey && signTransaction) {
            w.walletActions = {
                publicKey,
                signTransaction
            };
            setData({
                publicKey,
                signTransaction
            })
        }
    }, [publicKey, signTransaction])

    // const value: FirebaseFeatures = { firestore, auth, functions }
    return <WalletWrapperContext.Provider value={data}>{children}</WalletWrapperContext.Provider>
}


