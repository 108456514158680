/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from "react";
import { VaultItem } from "./VaultItem/VaultItem";
import "./VaultItemsSection.scss";
import CircularProgress from "@mui/material/CircularProgress";
import { useVault } from "../../Services/VaultService/VaultService";
import ContractInformationDialog from "./ContractInformationDialog/ContractInformationDialog";

export const VaultItemsSection: React.FC<any> = () => {
  const {
    stakedItems,
    unstakedItems,
    busyStakingTokenAddress,
    stakingProjectData,
    loadingUnstaked,
    unstakingDocs,
    multiplierStakedDocs,
  } = useVault();

  const [informationContract, setInformationContract] = useState<any>(null);

  useEffect(() => {
    if (
      stakedItems &&
      unstakedItems &&
      busyStakingTokenAddress &&
      stakingProjectData
    ) {
      console.log(
        unstakedItems &&
          unstakedItems!
            .filter(
              (item) =>
                stakedItems?.find(
                  (stakedItem) => stakedItem.mintAddress === item.mintAddress
                ) === undefined
            )
            .filter(
              (item) =>
                multiplierStakedDocs?.find(
                  (stakedItem) =>
                    stakedItem.data().mintAddress === item.mintAddress
                ) === undefined
            )
            .map((item) => ({
              ...item,
              busyStaking: busyStakingTokenAddress!.includes(item.mintAddress),
            }))
      );
    }
  }, [
    stakedItems,
    unstakedItems,
    busyStakingTokenAddress,
    stakingProjectData,
    multiplierStakedDocs,
  ]);

  // console.log(unstakedItems);

  // functions.httpsCallable('V4GetTokensForWallet')(
  //     {
  //         vaultId,
  //         walletAddress: '8GetxQN9szgwjLZP9XwLsREGhmqQAkWyfsgQtAoAEuSG'
  //     }
  // ).then(res => {
  //     const stakedTokens: TokenData[] = res.data.map((item: any) => ({
  //         ...item,
  //         busyStaking: false
  //     }));
  //     resolve(stakedTokens);
  // }).catch(err => {
  //     console.log(err);

  //     resolve([]);
  // })
  if (unstakingDocs === undefined) return <></>;
  return (
    <>
      {stakingProjectData && (
        <>
          {/* {stakedItems && stakedItems.length > 0 && <div className="section-title">
            STAKED
        </div>} */}
          <div className="vault-items-section-container">
            {stakedItems &&
              stakedItems!.map((i) => (
                <VaultItem
                  key={`${i.DocId}-${i.expirationDate?.getTime()}`}
                  itemData={i}
                  showInformationModal={setInformationContract}
                />
              ))}
          </div>
          {unstakedItems &&
            unstakedItems!
              .filter(
                (item) =>
                  stakedItems?.find(
                    (stakedItem) => stakedItem.mintAddress === item.mintAddress
                  ) === undefined
              )
              .filter(
                (item) =>
                  multiplierStakedDocs?.find(
                    (stakedItem) =>
                      stakedItem.data().mintAddress === item.mintAddress
                  ) === undefined
              ).length > 0 && (
              <div className="section-title-unstaked">NOT STAKED</div>
            )}
          {loadingUnstaked && (
            <div
              style={{ marginTop: "30px" }}
              className="vault-items-section-container"
            >
              <CircularProgress color="inherit" />
            </div>
          )}
          <div className="vault-items-section-container">
            {unstakedItems &&
              unstakedItems!
                .filter(
                  (item) =>
                    stakedItems?.find(
                      (stakedItem) =>
                        stakedItem.mintAddress === item.mintAddress
                    ) === undefined
                )
                .filter(
                  (item) =>
                    multiplierStakedDocs?.find(
                      (stakedItem) =>
                        stakedItem.data().mintAddress === item.mintAddress
                    ) === undefined
                )
                .map((item) => ({
                  ...item,
                  busyStaking: busyStakingTokenAddress!.includes(
                    item.mintAddress
                  ),
                }))
                .map((i) => (
                  <VaultItem
                    key={i?.tokenData?.name}
                    itemData={i}
                    showInformationModal={setInformationContract}
                  />
                ))}
          </div>
          <ContractInformationDialog
            contract={informationContract}
            hide={() => setInformationContract(false)}
          />
        </>
      )}
    </>
  );
};
