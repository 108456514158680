// import React from 'react';
// import ReactDOM from 'react-dom';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// import './index.css';
// import { WalletAdapter } from './services/WalletAdapter/WalletAdapter';

// ReactDOM.render(
//   <React.StrictMode>
//     <WalletAdapter />
//   </React.StrictMode>,
//   document.getElementById('root'),
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';

import App from './App';
import { FirebaseProvider } from './pages/Vault/Services/FirebaseService/FirebaseService';
import { ScreenSizeProvider } from './pages/Vault/Services/ScreenSizeService/ScreenSizeService';
import { WalletAdapter } from './pages/Vault/Services/WalletAdapter/WalletAdapter';
import { WalletWrapperService } from './pages/Vault/Services/WalletWrapperService/WalletWrapperService';

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <ToastProvider>
        <FirebaseProvider>
          <WalletAdapter>
              <ScreenSizeProvider>
                <WalletWrapperService>
                  <App />
                </WalletWrapperService>
              </ScreenSizeProvider>
          </WalletAdapter>
        </FirebaseProvider>
      </ToastProvider>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root')
);
