import React, { useContext, createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFirebase } from "../FirebaseService/FirebaseService";
import * as axios from "axios";
import { programs } from "@metaplex/js";
import { Connection } from "@solana/web3.js";
import { useWallet } from "@solana/wallet-adapter-react";

export type TokenData = {
  staked: boolean;
  busyStaking: boolean;
  mintAddress: string;
  tokenData: any;
  multiplierInfo: any;
  created?: Date;
  expirationDate?: Date;
  dailyReward?: number;
  TokenName?: string;
  RewardProgram?: string;
  StakingProgram?: string;
  DocId?: string;
};
export type VaultData = {
  selectedVault?: any;
  selectedVaultData?: any;
  stakingProjectData?: any;
  stakedItems?: TokenData[];
  unstakedItems?: TokenData[];
  vaultId?: string;
  stakedDocs?: any[];
  multiplierStakedDocs?: any[];
  stakingPlans?: any[];
  busyStakingTokenAddress?: string[];
  handleGetTokensInWallet?: any;
  autostakingConfigDoc?: any;
  loadingUnstaked?: any;
  withdrawingDocs?: any;
  unstakingDocs?: any;
  hideWithdraw?: any;
  vaultConfig?: any;
};

export const VaultContext = createContext<VaultData>({});

export const useVault = () => {
  return useContext(VaultContext);
};

export const VaultProvider = ({ children, stakingProjectId }: any) => {
  const [vaultConfig, setVaultConfig] = useState<any>();

  const [stakingProjectData, setStakingProjectData] = useState<any>();
  const [selectedVault, setSelectedVault] = useState<any>();
  const [hideWithdraw, setHideWithdraw] = useState<any>();
  const [selectedVaultData, setSelectedVaultData] = useState<any>();
  const [autostakingConfigDoc, setAutostakingConfigDoc] = useState<any>();
  const [stakingPlans, setStakingPlans] = useState<any>();
  const { firestore, customFirebaseRequest, functions } = useFirebase();
  const [busyStakingAddress, setBusyStakingAddress] = useState<string[]>([]);
  const [staked, setStaked] = useState<TokenData[]>();
  const [multiplierStakedDocs, setMultiplierStakedDocs] = useState<any[]>();
  const [stakedDocs, setStakedDocs] = useState<any[]>();
  const [unstaked, setUnstaked] = useState<TokenData[]>([]);
  const [itemsData, setItemsData] = useState<TokenData[]>([]);
  const [vaultId, setVaultId] = useState<string | undefined>();
  const [loadingUnstaked, setLoadingUnstaked] = useState<any>(false);
  const [withdrawingDocs, setWithdrawingDocs] = useState<any>();
  const [unstakingDocs, setUnstakingDocs] = useState<any>();
  const [publicKey] = useState({
    toString: () => "7pX4AHELxcn7taJeJHD8EPaFuYz2Z86YLJVebe1mcJLe",
  });

  // const { publicKey } = useWallet();

  const navigate = useNavigate();

  useEffect(() => {
    return firestore
      .doc("/AOverallStats/DiamondVaultConfig")
      .onSnapshot((doc) => {
        setVaultConfig(doc.data());
      });
  }, []);

  useEffect(() => {
    if (stakingProjectId) {
      setupStakingProject();
    }
  }, [stakingProjectId]);

  const setupStakingProject = async () => {
    functions
      .httpsCallable("V5User_searchForVaultByRoute3", { timeout: 120000 })({
        route: stakingProjectId,
      })
      .then(({ data }) => {
        if (data.success) {
          if (data.has) {
            setStakingPlans(data.stakinPrograms);
            setStakingProjectData(data.vault);
            setVaultId(data.vault.VaultId);
            setHideWithdraw(data.vault.HideWithdraw === true);
          } else {
            navigate("/");
          }
        } else {
          navigate("/");
        }
      })
      .catch((err) => {
        console.log(err);
        customFirebaseRequest("V5User_searchForVaultByRoute", {
          route: stakingProjectId,
        }).then((res) => {
          if (res.success) {
            if (res.has) {
              setStakingPlans(res.stakinPrograms);
              setStakingProjectData(res.vault);
              setVaultId(res.vault.VaultId);
              setHideWithdraw(res.vault.HideWithdraw === true);
            } else {
              navigate("/");
            }
          } else {
            navigate("/");
          }
        });
      });
  };

  useEffect(() => {
    if (stakingProjectData && publicKey) {
      let unsubscribes: (() => void)[] = [];
      unsubscribes = getStakedVault();
      return () => {
        for (const unsubscribe of unsubscribes) {
          unsubscribe();
        }
      };
    }
    return;
  }, [stakingProjectData, publicKey]);

  useEffect(() => {
    setItemsData([
      ...(staked || []),
      ...unstaked.map((item) => ({
        ...item,
        busyStaking: busyStakingAddress.includes(item.mintAddress),
      })),
    ]);
  }, [busyStakingAddress, staked, unstaked]);

  const handleGetTokensInWallet = (VaultId: any, ProjectId: any) => {
    getTokensInWallet(publicKey!.toString(), VaultId, ProjectId)
      .then((res) => {
        setUnstaked(res);
      })
      .catch();
  };
  const getStakedVault = () => {
    const { VaultId, ProjectId } = stakingProjectData;

    handleGetTokensInWallet(VaultId, ProjectId);

    const unsubscribes = [
      firestore
        .doc(
          `/AVaultProject/${ProjectId}/AVault/${VaultId}/AAutoStakingConfig/Config`
        )
        .onSnapshot((doc) => {
          if (doc.exists) {
            setAutostakingConfigDoc(doc);
          }
        }),
      firestore
        .collection(
          `/AVaultProject/${ProjectId}/AVault/${VaultId}/AWithdrawRequestQueue`
        )
        .where("walletAddress", "==", publicKey?.toString())
        .where("Status", "in", [-1, 0, 1])
        .onSnapshot(({ docs }) => {
          setWithdrawingDocs(docs);
        }),
      firestore
        .collection(
          `/AVaultProject/${ProjectId}/AVault/${VaultId}/AUnstakeRequestQueue`
        )
        .where("walletAddress", "==", publicKey?.toString())
        .where("Status", "in", [-1, 0, 1])
        .onSnapshot(({ docs }) => {
          const unstakingDocs = docs
            .map((doc) => {
              return doc.data().stakedDocsIds;
            })
            .flat();
          setUnstakingDocs(unstakingDocs);
        }),
      firestore
        .collection(
          `/AVaultProject/${ProjectId}/AVault/${VaultId}/AStakeRequestQueue`
        )
        .where("walletAddress", "==", publicKey?.toString())
        .where("Status", "in", [-1, 0, 1])
        .onSnapshot(({ docs }) => {
          const stakingNFTAddress = docs
            .map((doc) => {
              alert(doc.ref.path);
              return doc.data().TokenAddress;
            })
            .flat();
          setBusyStakingAddress(stakingNFTAddress);
        }),
      firestore
        .collection(
          `/AVaultProject/${ProjectId}/AVault/${VaultId}/AStakedToken`
        )
        .where("StakerPublicKey", "==", publicKey?.toString())
        .orderBy("created", "desc")
        .onSnapshot((stakedDocs) => {
          setStakedDocs(stakedDocs.docs);
          const staked: TokenData[] = stakedDocs.docs.map((doc) => {
            const {
              manifest,
              mintAddress,
              created,
              expirationDate,
              PlanId,
              stakingSession,
              RewardProgram,
              MultiplierInfo,
              StakingProgram,
              baseDailyReward,
              dailyReward,
              TokenData: { TokenName },
            } = doc.data();
            console.log(doc.ref.path);

            return {
              staked: true,
              busyStaking: false,
              mintAddress,
              expirationDate: expirationDate.toDate(),
              baseDailyReward,
              dailyReward,
              TokenName,
              PlanId,
              stakingSession,
              RewardProgram,
              multiplierInfo: MultiplierInfo,
              StakingProgram,
              DocId: doc.id,
              tokenData: manifest,
              created: created.toDate(),
            };
          });
          setStaked(staked);
        }),
      firestore
        .collection(
          `/AVaultProject/${ProjectId}/AVault/${VaultId}/AMultiplierStakedToken`
        )
        .where("StakerPublicKey", "==", publicKey?.toString())
        // .orderBy("created", "desc")
        .onSnapshot((stakedDocs) => {
          console.log(stakedDocs.docs);
          setMultiplierStakedDocs(stakedDocs.docs);
        }),
      // firestore.collection(`/AVaultProject/${ProjectId}/AVault/${VaultId}/AVaultStakingRequest`)
      //     .where('Status', '==', 1)
      //     .where('StakerPublicKey', '==', publicKey?.toString())
      //     .onSnapshot(stakedDocs => {
      //         const tokens = []
      //         for (let index = 0; index < stakedDocs.docs.length; index++) {
      //             const { TokenAddress } = stakedDocs.docs[index].data();
      //             tokens.push(...TokenAddress)
      //         }
      //         setBusyStakingAddress([...tokens])
      //     })
    ];
    return unsubscribes;
  };

  const getTokensInWallet = (
    publicKey: string,
    vaultId: string,
    projectId: string
  ) =>
    new Promise<TokenData[]>((resolve) => {
      const { VaultId, ProjectId } = stakingProjectData;
      setLoadingUnstaked(true);

      customFirebaseRequest("V5User_checkNoncustodialForWallet", {
        vaultId: VaultId,
        projectId: ProjectId,
        walletAddress: publicKey?.toString(),
      })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
      customFirebaseRequest("V5User_getTokensInWallet", {
        vaultId: VaultId,
        projectId: ProjectId,
        walletAddress: publicKey?.toString(),
      })
        .then(async (res) => {
          const stakedTokens: TokenData[] = [];
          const arrays: any = [],
            size = 1;
          const supportedTokensCopy = [...res];
          while (supportedTokensCopy.length > 0)
            arrays.push(supportedTokensCopy.splice(0, size));

          for (const tokenArray of arrays) {
            await Promise.all(
              tokenArray.map(async (token: any) => {
                const tokenData: any =
                  token.tokenData || (await getMetadata(token.mintAddress));
                if (tokenData)
                  stakedTokens.push({
                    ...token,
                    busyStaking: false,
                    tokenData,
                  });
                return {
                  ...token,
                  busyStaking: false,
                  tokenData,
                };
              })
            );
          }
          setLoadingUnstaked(false);

          resolve(stakedTokens);
        })
        .catch((err) => {
          setLoadingUnstaked(false);
          resolve([]);
        });
    });

  const value = {
    selectedVault,
    stakingProjectData,
    stakedItems: staked,
    unstakedItems: unstaked,
    vaultId,
    stakedDocs,
    multiplierStakedDocs,
    selectedVaultData,
    stakingPlans,
    handleGetTokensInWallet,
    autostakingConfigDoc,
    loadingUnstaked,
    withdrawingDocs,
    unstakingDocs,
    busyStakingTokenAddress: busyStakingAddress,
    hideWithdraw,
    vaultConfig,
  };
  if (!vaultConfig) return <></>;

  return (
    <VaultContext.Provider value={value}>{children}</VaultContext.Provider>
  );
};

export const getMetadata = async (
  tokenPubKey: string,
  attempt = 0,
  maxAttempt = 100
): Promise<any> => {
  if (attempt === maxAttempt) {
    return undefined;
  }
  const {
    metadata: { Metadata },
  } = programs;
  // const metaplexConnection = new mConnection();
  const connection = new Connection(
    "https://weathered-white-sunset.solana-mainnet.quiknode.pro/25f8d47456fd094f2a4bcead278e3683b7a7762f/"
  );
  try {
    const addr = await Metadata.getPDA(tokenPubKey);

    const resp = await Metadata.load(connection, addr);
    const getData = () =>
      new Promise<any>((resolve) => {
        axios.default
          .get(resp.data.data.uri, {
            timeout: 10000,
          })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((err) => {
            console.log("getMetadata err", err);
            resolve(undefined);
          });
      });
    // const { data } = await axios.default.get(resp.data.data.uri,, {
    //     timeout: 5000
    // });
    return await getData();
  } catch (error) {
    console.log("error fetching metadata: ", error, attempt);
    return await getMetadata(tokenPubKey, attempt + 1);
  }
};
