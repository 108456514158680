import "./Vault.scss";
import { VaultInfoSection } from "./Components/VaultInfoSection/VaultInfoSection";
import { VaultStatsSection } from "./Components/VaultStatsSection/VaultStatsSection";
import { VaultItemsSection } from "./Components/VaultItemsSection/VaultItemsSection";
import { VaultToolbar } from "./Components/VaultToolbar/VaultToolbar";
import { useWallet } from "@solana/wallet-adapter-react";
import { useEffect, useState } from "react";
import { VaultBalance } from "./Components/VaultBalance/VaultBalance";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useNavigate, useParams } from "react-router-dom";

import { MultiplierStakingModalProvider } from "./Components/MultiplierSection/MultiplierStakingModalService/MultiplierStakingModalService";
import { MultiplierSection } from "./Components/MultiplierSection/MultiplierSection";
import { VaultProvider } from "./Services/VaultService/VaultService";
import { StakeModalProvider } from "./Services/StakeModalService/StakeModalService";
import { UnstakeModalProvider } from "./Services/UnstakeModalService/UnstakeModalService";
import { UnstakeMultiplierModalProvider } from "./Components/MultiplierSection/UnstakeMultiplierModalService/UnstakeModalService";
export const Vault = () => {
  const [stakingProjectId, setStakingProjectId] = useState<any>(null);
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (params["id"]) {
      window.location.href = `https://www.anybodies.com/c/${params["id"]}`
      setStakingProjectId(params["id"]);
    } else {
      navigate("/");
    }
  }, []);

  return <VaultProvider stakingProjectId={stakingProjectId}>
     <VaultContent/>
  </VaultProvider>;
};

export const ExternalEmbedVault = ({ vaultPath }: any) => {
  return (
    <VaultProvider stakingProjectId={vaultPath}>
      <VaultContent/>
    </VaultProvider>
  );
};

export const VaultWalletWrapper = () => {
  const [start, setStart] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setStart(true);
    }, 1000);
  }, []);
  return (
    <div className="vault-wallet-wrapper">
      {start && (
        <>
          <div className="connect-alert">
            Connect your wallet to access the vault
          </div>
          <WalletMultiButton className="wallet-btn" />
        </>
      )}
    </div>
  );
};

export const VaultContent = () => {
  const { publicKey } = useWallet();
  return (
    <>
      <UnstakeMultiplierModalProvider>
        <MultiplierStakingModalProvider>
          <StakeModalProvider>
            <UnstakeModalProvider>
              <div className="vault-main-container">
                <VaultInfoSection />
                <VaultStatsSection />
                {publicKey ? (
                  <>
                    {/* <h1 className="issue">
                  SOLANA is currently experiencing a "System Outage" on the whole network, as
                  announced{" "}
                  <a
                    style={{ color: "wheat" }}
                    href="https://status.solana.com/"
                    target="_blank"
                  >
                    here.
                  </a>{" "}
                  <br />
                  Our service will resume once their systems are back up.
                  Thanks for your patience
                </h1> */}
                    <VaultBalance />
                    <MultiplierSection />
                    <VaultToolbar />
                    <VaultItemsSection />
                  </>
                ) : (
                  <VaultWalletWrapper />
                )}
              </div>
            </UnstakeModalProvider>
          </StakeModalProvider>
        </MultiplierStakingModalProvider>
      </UnstakeMultiplierModalProvider>
    </>
  );
};
