export const Privacy = () => {
  return (
    <div>
      <div style={{ padding: "20px", color: "white" }}>
        <div className="c6">
          <h1 className="c12" id="h.1131pkyaj2qd">
            <span className="c9">Privacy policy</span>
          </h1>
          <p className="c3">
            <span className="c0">
              This privacy policy (&quot;Policy&quot;) describes how Diamond Vaults
              (&quot;Diamond Vaults&quot;, &quot;we&quot;, &quot;us&quot; or
              &quot;our&quot;) collects, protects and uses the personally
              identifiable information (&quot;Personal Information&quot;) you
              (&quot;User&quot;, &quot;you&quot; or &quot;your&quot;) may
              provide on the Diamond Vaults web application and any of its products or
              services (collectively, &quot;web Application&quot; or
              &quot;Services&quot;). It also describes the choices available to
              you regarding our use of your Personal Information and how you can
              access and update this information. This Policy does not apply to
              the practices of companies that we do not own or control, or to
              individuals that we do not employ or manage.
            </span>
          </p>
          <h2 className="c5" id="h.soi3q996ziaf">
            <span className="c4">Automatic collection of information</span>
          </h2>
          <p className="c3">
            <span className="c0">
              When you visit the web Application our servers automatically
              record information that your browser sends. This data may include
              information such as your device&#39;s IP address, browser type and
              version, operating system type and version, language preferences
              or the webpage you were visiting before you came to our web
              Application, pages of our web Application that you visit, the
              time spent on those pages, information you search for on our
              web Application, access times and dates, and other statistics.
            </span>
          </p>
          <h2 className="c5" id="h.fz10gw4dtaf7">
            <span className="c4">Collection of personal information</span>
          </h2>
          <p className="c3">
            <span className="c0">
              You can visit the web Application without telling us who you
              are or revealing any information by which someone could identify
              you as a specific, identifiable individual. If, however, you wish
              to use some of the web Application&#39;s features, you will be
              asked to provide certain Personal Information (for example, your
              name and e-mail address). We receive and store any information you
              knowingly provide to us when you create an account, publish
              content, make a purchase, or fill any online forms on the web
              Application. When required, this information may include your
              email address, name, phone number, address, credit card
              information, bank information, or other Personal Information. You
              can choose not to provide us with your Personal Information, but
              then you may not be able to take advantage of some of the web
              Application&#39;s features. Users who are uncertain about what
              information is mandatory are welcome to contact us.
            </span>
          </p>
          <h2 className="c5" id="h.6bz9jyr814gp">
            <span className="c4">Managing personal information</span>
          </h2>
          <p className="c3">
            <span className="c0">
              You are able to access, add to, update and delete certain Personal
              Information about you. The information you can view, update, and
              delete may change as the web Application or Services change.
              When you update information, however, we may maintain a copy of
              the unrevised information in our records. Some information may
              remain in our private records after your deletion of such
              information from your account. We will retain and use your
              Personal Information for the period necessary to comply with our
              legal obligations, resolve disputes, and enforce our agreements
              unless a longer retention period is required or permitted by law.
              We may use any aggregated data derived from or incorporating your
              Personal Information after you update or delete it, but not in a
              manner that would identify you personally. Once the retention
              period expires, Personal Information shall be deleted. Therefore,
              the right to access, the right to erasure, the right to
              rectification and the right to data portability cannot be enforced
              after the expiration of the retention period.
            </span>
          </p>
          <h2 className="c5" id="h.nir6sf31nfig">
            <span className="c4">
              Use and processing of collected information
            </span>
          </h2>
          <p className="c3">
            <span className="c0">
              Any of the information we collect from you may be used to
              personalize your experience; improve our web Application;
              improve customer service and respond to queries and emails of our
              customers; process transactions; send newsletters; send
              notification emails such as password reminders, updates, etc; run
              and operate our web Application and Services. Information
              collected automatically is used only to identify potential cases
              of abuse and establish statistical information regarding web
              Application usage. This statistical information is not otherwise
              aggregated in such a way that would identify any particular user
              of the system.
            </span>
          </p>
          <p className="c3">
            <span className="c0">
              We may process Personal Information related to you if one of the
              following applies: (i) You have given your consent for one or more
              specific purposes. Note that under some legislations we may be
              allowed to process information until you object to such processing
              (by opting out), without having to rely on consent or any other of
              the following legal bases below. This, however, does not apply,
              whenever the processing of Personal Information is subject to
              European data protection law; (ii) Provision of information is
              necessary for the performance of an agreement with you and/or for
              any pre-contractual obligations thereof; (iii) Processing is
              necessary for compliance with a legal obligation to which you are
              subject; (iv) Processing is related to a task that is carried out
              in the public interest or in the exercise of official authority
              vested in us; (v) Processing is necessary for the purposes of the
              legitimate interests pursued by us or by a third party. In any
              case, we will be happy to clarify the specific legal basis that
              applies to the processing, and in particular whether the provision
              of Personal Information is a statutory or contractual requirement,
              or a requirement necessary to enter into a contract.
            </span>
          </p>
          <h2 className="c5" id="h.pxeyqhqjdpgi">
            <span className="c4">Information transfer and storage</span>
          </h2>
          <p className="c3">
            <span className="c0">
              Depending on your location, data transfers may involve
              transferring and storing your information in a country other than
              your own. You are entitled to learn about the legal basis of
              information transfers to a country outside the European Union or
              to any international organization governed by public international
              law or set up by two or more countries, such as the UN, and about
              the security measures taken by us to safeguard your information.
              If any such transfer takes place, you can find out more by
              checking the relevant sections of this document or inquire with us
              using the information provided in the contact section.
            </span>
          </p>
          <h2 className="c5" id="h.u3mwsiev03lr">
            <span className="c4">The rights of users</span>
          </h2>
          <p className="c3">
            <span className="c0">
              You may exercise certain rights regarding your information
              processed by us. In particular, you have the right to do the
              following: (i) you have the right to withdraw consent where you
              have previously given your consent to the processing of your
              information; (ii) you have the right to object to the processing
              of your information if the processing is carried out on a legal
              basis other than consent; (iii) you have the right to learn if
              information is being processed by us, obtain disclosure regarding
              certain aspects of the processing and obtain a copy of the
              information undergoing processing; (iv) you have the right to
              verify the accuracy of your information and ask for it to be
              updated or corrected; (v) you have the right, under certain
              circumstances, to restrict the processing of your information, in
              which case, we will not process your information for any purpose
              other than storing it; (vi) you have the right, under certain
              circumstances, to obtain the erasure of your Personal Information
              from us; (vii) you have the right to receive your information in a
              structured, commonly used and machine readable format and, if
              technically feasible, to have it transmitted to another controller
              without any hindrance. This provision is applicable provided that
              your information is processed by automated means and that the
              processing is based on your consent, on a contract which you are
              part of or on pre-contractual obligations thereof.
            </span>
          </p>
          <h2 className="c5" id="h.awozheb7e91d">
            <span className="c4">The right to object to processing</span>
          </h2>
          <p className="c3">
            <span className="c0">
              Where Personal Information is processed for the public interest,
              in the exercise of an official authority vested in us or for the
              purposes of the legitimate interests pursued by us, you may object
              to such processing by providing a ground related to your
              particular situation to justify the objection. You must know that,
              however, should your Personal Information be processed for direct
              marketing purposes, you can object to that processing at any time
              without providing any justification. To learn, whether we are
              processing Personal Information for direct marketing purposes, you
              may refer to the relevant sections of this document.
            </span>
          </p>
          <h2 className="c5" id="h.rhg4g7dbk9fm">
            <span className="c4">How to exercise these rights</span>
          </h2>
          <p className="c3">
            <span className="c0">
              Any requests to exercise User rights can be directed to the Owner
              through the contact details provided in this document. These
              requests can be exercised free of charge and will be addressed by
              the Owner as early as possible.
            </span>
          </p>
          <h2 className="c5" id="h.cid1gjg1jou4">
            <span className="c4">Billing and payments</span>
          </h2>
          <p className="c3">
            <span className="c0">
              In case of services requiring payment, we request credit card or
              other payment account information, which will be used solely for
              processing payments. Your purchase transaction data is stored only
              as long as is necessary to complete your purchase transaction.
              After that is complete, your purchase transaction information is
              deleted. All direct payment gateways adhere to the latest security
              standards as managed by the PCI Security Standards Council, which
              is a joint effort of brands like Visa, MasterCard, American
              Express and Discover. Sensitive and private data exchange happens
              over a SSL secured communication channel and is encrypted and
              protected with digital signatures.
            </span>
          </p>
          <h2 className="c5" id="h.vm56dxr0l65h">
            <span className="c4">Product and service providers</span>
          </h2>
          <p className="c3">
            <span className="c0">
              We may contract with other companies to provide certain products
              and services. These service providers are not authorized to use or
              disclose the information except as necessary to perform services
              on our behalf or comply with legal requirements. We may share
              Personal Information for these purposes only with third-parties
              whose privacy policies are consistent with ours or who agree to
              abide by our policies with respect to Personal Information Our
              service providers are given the information they need to perform
              their designated functions, and we do not authorize them to use or
              disclose Personal Information for their own marketing or other
              purposes.
            </span>
          </p>
          <h2 className="c5" id="h.i16d9183do1">
            <span className="c4">Privacy of children</span>
          </h2>
          <p className="c3">
            <span className="c0">
              We take many special precautions to protect the privacy of
              children under 13. We do not require a child to disclose more
              information than is reasonably necessary to use the web
              Application and Services. We encourage children to consult with
              their parents before submitting any information to any web
              Application, including agent.unreal-j.com. We believe parents
              should be involved in the online activities of their children and
              suggest that parents do their best to provide their children with
              a safe and friendly online environment.
            </span>
          </p>
          <h2 className="c5" id="h.e4tpv5s1d76m">
            <span className="c4">Newsletters</span>
          </h2>
          <p className="c3">
            <span className="c0">
              We offer electronic newsletters to which you may voluntarily
              subscribe at any time. You may choose to stop receiving our
              newsletter or marketing emails by following the unsubscribe
              instructions included in these emails or by contacting us.
              However, you will continue to receive essential transactional
              emails.
            </span>
          </p>
          <h2 className="c5" id="h.uie97v6h4t1i">
            <span className="c4">Cookies</span>
          </h2>
          <p className="c3">
            <span className="c2">
              The web Application uses &quot;cookies&quot; to help
              personalize your online experience. A cookie is a text file that
              is placed on your hard disk by a web page server. Cookies cannot
              be used to run programs or deliver viruses to your computer.
              Cookies are uniquely assigned to you, and can only be read by a
              web server in the domain that issued the cookie to you. We may use
              cookies to collect, store, and track information for statistical
              purposes to operate our web Application and Services. You have
              the ability to accept or decline cookies. Most web browsers
              automatically accept cookies, but you can usually modify your
              browser setting to decline cookies if you prefer. If you choose to
              decline cookies, you will not be able to use and experience the
              features of the web Application and Services. To learn more
              about cookies and how to manage them, visit{" "}
            </span>
            <span className="c2 c7">
              <a
                className="c1"
                href="https://www.google.com/url?q=https://www.internetcookies.org/&amp;sa=D&amp;ust=1602932889875000&amp;usg=AOvVaw3I-K3HK2zti26x0gJiZcbj"
              >
                internetcookies.org
              </a>
            </span>
          </p>
          <p className="c3">
            <span className="c0">
              In addition to using cookies and related technologies as described
              above, we also may permit certain third-party companies to help us
              tailor advertising that we think may be of interest to users and
              to collect and use other data about user activities on the web
              Application. These companies may deliver ads that might also place
              cookies and otherwise track user behavior.
            </span>
          </p>
          <h2 className="c5" id="h.2i79a0bwimhe">
            <span className="c4">Do Not Track signals</span>
          </h2>
          <p className="c3">
            <span className="c0">
              Some browsers incorporate a Do Not Track feature that signals to
              web Applications you visit that you do not want to have your
              online activity tracked. Tracking is not the same as using or
              collecting information in connection with a web Application.
              For these purposes, tracking refers to collecting personally
              identifiable information from consumers who use or visit a web
              Application or online service as they move across different web
              Applications over time. Our web Application does not track its
              visitors over time and across third party web Applications.
              However, some third party sites may keep track of your browsing
              activities when they serve you content, which enables them to
              tailor what they present to you.
            </span>
          </p>
          <h2 className="c5" id="h.nlj0z6ypeo8p">
            <span className="c4">Advertisement</span>
          </h2>
          <p className="c3">
            <span className="c0">
              We may display online advertisements and we may share aggregated
              and non-identifying information about our customers that we
              collect through the registration process or through online surveys
              and promotions with certain advertisers. We do not share
              personally identifiable information about individual customers
              with advertisers. In some instances, we may use this aggregated
              and non-identifying information to deliver tailored advertisements
              to the intended audience.
            </span>
          </p>
          <h2 className="c5" id="h.uyc4406lof93">
            <span className="c4">Affiliates</span>
          </h2>
          <p className="c3">
            <span className="c0">
              We may disclose information about you to our affiliates for the
              purpose of being able to offer you related or additional products
              and services. Any information relating to you that we provide to
              our affiliates will be treated by those affiliates in accordance
              with the terms of this Privacy Policy.
            </span>
          </p>
          <h2 className="c5" id="h.9yzeg8u3cf4e">
            <span className="c4">
              Links to other web applications or websites
            </span>
          </h2>
          <p className="c3">
            <span className="c0">
              Our web Application contains links to other web Applications
              or Websites that are not owned or controlled by us. Please be
              aware that we are not responsible for the privacy practices of
              such other web Applications or Websites or third-parties. We
              encourage you to be aware when you leave our web Application
              and to read the privacy statements of each and every web
              Application or Website that may collect Personal Information.
            </span>
          </p>
          <h2 className="c5" id="h.ktbsraxcsbk1">
            <span className="c4">Information security</span>
          </h2>
          <p className="c3">
            <span className="c0">
              We secure information you provide on computer servers in a
              controlled, secure environment, protected from unauthorized
              access, use, or disclosure. We maintain reasonable administrative,
              technical, and physical safeguards in an effort to protect against
              unauthorized access, use, modification, and disclosure of Personal
              Information in its control and custody. However, no data
              transmission over the Internet or wireless network can be
              guaranteed. Therefore, while we strive to protect your Personal
              Information, you acknowledge that (i) there are security and
              privacy limitations of the Internet which are beyond our control;
              (ii) the security, integrity, and privacy of any and all
              information and data exchanged between you and our web
              Application cannot be guaranteed; and (iii) any such information
              and data may be viewed or tampered with in transit by a
              third-party, despite best efforts.
            </span>
          </p>
          <h2 className="c5" id="h.1gn4mrhozu85">
            <span className="c4">Data breach</span>
          </h2>
          <p className="c3">
            <span className="c0">
              In the event we become aware that the security of the web
              Application has been compromised or users Personal Information has
              been disclosed to unrelated third parties as a result of external
              activity, including, but not limited to, security attacks or
              fraud, we reserve the right to take reasonably appropriate
              measures, including, but not limited to, investigation and
              reporting, as well as notification to and cooperation with law
              enforcement authorities. In the event of a data breach, we will
              make reasonable efforts to notify affected individuals if we
              believe that there is a reasonable risk of harm to the user as a
              result of the breach or if notice is otherwise required by law.
              When we do, we will post a notice on the web Application.
            </span>
          </p>
          <h2 className="c5" id="h.4puo7cutwdz7">
            <span className="c4">Legal disclosure</span>
          </h2>
          <p className="c3">
            <span className="c0">
              We will disclose any information we collect, use or receive if
              required or permitted by law, such as to comply with a subpoena,
              or similar legal process, and when we believe in good faith that
              disclosure is necessary to protect our rights, protect your safety
              or the safety of others, investigate fraud, or respond to a
              government request. In the event we go through a business
              transition, such as a merger or acquisition by another company, or
              sale of all or a portion of its assets, your user account, and
              Personal Information will likely be among the assets transferred.
            </span>
          </p>
          <h2 className="c5" id="h.vwaclijpgkxg">
            <span className="c4">Changes and amendments</span>
          </h2>
          <p className="c3">
            <span className="c0">
              We may update this Privacy Policy from time to time in our
              discretion and will notify you of any material changes to the way
              in which we treat Personal Information. When changes are made, we
              will revise the updated date at the bottom of this page. We may
              also provide notice to you in other ways in our discretion, such
              as through contact information you have provided. Any updated
              version of this Privacy Policy will be effective immediately upon
              the posting of the revised Privacy Policy unless otherwise
              specified. Your continued use of the web Application or
              Services after the effective date of the revised Privacy Policy
              (or such other act specified at that time) will constitute your
              consent to those changes. However, we will not, without your
              consent, use your Personal Data in a manner materially different
              than what was stated at the time your Personal Data was collected.
            </span>
          </p>
          <h2 className="c5" id="h.amkha0cug2qn">
            <span className="c4">Acceptance of this policy</span>
          </h2>
          <p className="c3">
            <span className="c0">
              You acknowledge that you have read this Policy and agree to all
              its terms and conditions. By using the web Application or its
              Services you agree to be bound by this Policy. If you do not agree
              to abide by the terms of this Policy, you are not authorized to
              use or access the web Application and its Services.
            </span>
          </p>
          <h2 className="c5" id="h.wa078n89ctzh">
            <span className="c4">Contacting us</span>
          </h2>
          <p className="c3">
            <span className="c0">
              If you would like to contact us to understand more about this
              Policy or wish to contact us concerning any matter relating to
              individual rights and your Personal Information, you may send an
              email to support@diamondvaults.io
            </span>
          </p>
          <p className="c3">
            <span className="c0">
              This document was last updated on May 10, 2022
            </span>
          </p>
          <p className="c11">
            <span className="c8 c10"></span>
          </p>
        </div>
      </div>
    </div>
  );
};
