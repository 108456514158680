import "./Overview.scss";
import preview from "./preview.png";
import lock from "./icons/lock.svg";
import engage from "./icons/engage.svg";
import coins from "./icons/coins.svg";
import store from "./icons/store.svg";

const items = [
  {
    title: "CUSTODIAL & NON-CUSTODIAL STAKING",
    desc: <>Create nft staking contracts that distribute rewards based on rarity, attributes or collections.</>,
    icon: lock,
  },
  {
    title: "ENGAGE TO EARN",
    desc: <>Like, share, react and create content on our supported social media platforms (Twitter & Discord) to earn rewards.</>,
    icon: engage,
  },
  {
    title: "MANAGE YOUR PORTFOLIO",
    desc: <>Easily keep track of your storefront's issued rewards, all in one place.</>,
    icon: coins,
  },
  {
    title: "STOREFRONT",
    desc: <>Use your rewards on giveaways, raffles, merch, real-world products and other utilities.</>,
    icon: store,
  },
];

export const Overview = () => {
  return (
    <div className="overview-main-container">
      <div className="spacer"></div>
      <div className="title-container">
        <div className="title">THE SIMPLEST WAY TO CREATE UTILITY</div>
        <div className="subtitle">
          a quick and easy no-code storefront for your community to enjoy
        </div>
      </div>

      <div className="contect-container">
        <div className="preview-container">
          <img className="img" src={preview} alt="Staking Preview" />
        </div>
        <div className="list-continer">
          {items.map(({ title, desc, icon }) => (
            <div className="item-container" key={title}>
              <div className="icon-container">
                <div className="icon-bg">
                  <img className="icon" src={icon} alt="" />
                </div>
              </div>
              <div className="content-container">
                <div className="title">{title}</div>
                <div className="description">{desc}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
