import { Connection, Transaction } from "@solana/web3.js";
import { sendSignedTransaction } from "../../services/web3/RetryTransaction";

export const gss = "https://weathered-white-sunset.solana-mainnet.quiknode.pro/25f8d47456fd094f2a4bcead278e3683b7a7762f/";
export const privateConnection = new Connection(
  "https://weathered-white-sunset.solana-mainnet.quiknode.pro/25f8d47456fd094f2a4bcead278e3683b7a7762f/"
);

// export const shouldDoLocaly = false
export const tryConfirmTransactionOfficial = async (
  finalTransaction: Transaction,
  RPC_URL: string = "https://weathered-white-sunset.solana-mainnet.quiknode.pro/25f8d47456fd094f2a4bcead278e3683b7a7762f/"
) => {

  const connection = new Connection(RPC_URL);
  try {
    const txid = await sendSignedTransaction({
      connection: connection,
      signedTransaction: finalTransaction,
    });
    if (txid) {
      return txid;
    } else {
      return undefined;
    }
  } catch (err) {
    return undefined;
  }
};

export const parseName = (name: string) => {
  const num = name.split("#").length - 1;

  switch (num) {
    case 0:
      return name || "";
    case 1: {
      if (name.split("#")?.[1]?.length > 0) {
        return `#${name.split("#")?.[1]}`;
      }
      return name || "";
    }

    case 2: {
      if (name.split("#")?.[2]?.length > 0) {
        return `#${name.split("#")?.[2]}`;
      }
      return name || "";
    }
    case 3: {
      if (name.split("#")?.[2]?.length > 0) {
        return `#${name.split("#")?.[2]?.split(" ")?.[0] || name}`;
      }
      return name || "";
    }
  }
  return name || "";
};