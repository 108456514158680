import { AButton } from "../../../AButton/AButton";
import "./CTA.scss";

export const CTA = () => {
  const openManager = () => {
    const w = window.open("https://manage.diamondvaults.io", "_black");
    w?.focus();
  };
  return (
    <div className="cta-main-container">
      <div className="content">
        <div className="question">where diamond hands are made</div>
        <AButton onClick={openManager}>SETUP YOUR VAULT</AButton>
      </div>
    </div>
  );
};
