import "./Features.scss";
import free from "./icons/free.svg";
import secure from "./icons/secure.svg";
import custom from "./icons/custom.svg";
const items = [
  {
    title: "FREE TO USE",
    desc: "Tons of features & integrations for FREE! No hidden costs.",
    icon: free,
  },
  {
    title: "SECURE ASSET STORAGE",
    desc: "All tokens are stored using industry-grade security infrastructure.",
    icon: secure,
  },
  {
    title: "SUPER CUSTOMIZABLE",
    desc: "Build & host personalized experiences of your vault, using our white label solution.",
    icon: custom,
  },
];

export const Features = () => {
  return (
    <div className="features-main-container">
      <div className="title-container">
        <div className="title">YOUR TRUSTED NFT REWARDS PLATFORM</div>
        <div className="subtitle">
          Here are a few reasons why you should choose Diamond Vault
        </div>
      </div>
      <div className="features-container">
        {items.map(({ title, desc, icon }) => (
          <div className="features-section">
            <div className="features-content-container">
              <img className="icon" src={icon} alt="Feature icon" />
              <div className="title">{title}</div>
              <div className="description">{desc}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
