import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  Token,
  TOKEN_PROGRAM_ID,
} from '@solana/spl-token';
import {
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  Transaction,
  TransactionInstruction,
} from '@solana/web3.js';
import { privateConnection } from '.';

export const createLocalSolTransaction = async (
  fromAddress: string,
  toAddress: string,
  solAmount: number,
  attempt = 0,
  maxAttemps = 5,
): Promise<Transaction | undefined> => {
  if (attempt === maxAttemps) {
    return undefined;
  }
  try {
    const instructions: TransactionInstruction[] = [];

    instructions.push(
      SystemProgram.transfer({
        fromPubkey: new PublicKey(fromAddress),
        toPubkey: new PublicKey(toAddress),
        lamports: solAmount * LAMPORTS_PER_SOL,
      }),
    );

    const transaction = new Transaction().add(...instructions);
    transaction.feePayer = new PublicKey(fromAddress);
    transaction.recentBlockhash = (
      await privateConnection.getRecentBlockhash()
    ).blockhash;
    return transaction;
  } catch (err) {
    console.log(err);
    return await createLocalSolTransaction(
      fromAddress,
      toAddress,
      solAmount,
      attempt + 1,
    );
  }
};

export const createLocalSolTransactionWithRewardATA = async (
  fromAddress: string,
  toAddress: string,
  solAmount: number,
  rewardTokenAddress: string,
  attempt = 0,
  maxAttemps = 5,
): Promise<Transaction | undefined> => {
  if (attempt === maxAttemps) {
    return undefined;
  }
  try {
    const instructions: TransactionInstruction[] = [];

    instructions.push(
      SystemProgram.transfer({
        fromPubkey: new PublicKey(fromAddress),
        toPubkey: new PublicKey(toAddress),
        lamports: solAmount * LAMPORTS_PER_SOL,
      }),
    );

    // ATA
    if (rewardTokenAddress !== 'So11111111111111111111111111111111111111112') {
      const associatedDestinationTokenAddr =
        await Token.getAssociatedTokenAddress(
          ASSOCIATED_TOKEN_PROGRAM_ID,
          TOKEN_PROGRAM_ID,
          new PublicKey(rewardTokenAddress),
          new PublicKey(fromAddress),
        );

      const receiverAccount = await privateConnection.getAccountInfo(
        associatedDestinationTokenAddr,
      );

      console.log(
        'Staker associatedDestinationTokenAddr',
        associatedDestinationTokenAddr.toString(),
      );
      console.log('Staker receiverAccount', receiverAccount);
      if (receiverAccount === null) {
        instructions.push(
          Token.createAssociatedTokenAccountInstruction(
            ASSOCIATED_TOKEN_PROGRAM_ID,
            TOKEN_PROGRAM_ID,
            new PublicKey(rewardTokenAddress),
            associatedDestinationTokenAddr,
            new PublicKey(fromAddress),
            new PublicKey(fromAddress),
          ),
        );
      }
    }

    const transaction = new Transaction().add(...instructions);
    transaction.feePayer = new PublicKey(fromAddress);
    transaction.recentBlockhash = (
      await privateConnection.getRecentBlockhash()
    ).blockhash;
    return transaction;
  } catch (err) {
    console.log(err);
    return await createLocalSolTransaction(
      fromAddress,
      toAddress,
      solAmount,
      attempt + 1,
    );
  }
};
