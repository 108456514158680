import { useWallet } from "@solana/wallet-adapter-react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import * as axios from "axios";
import "./Verify.scss";
import { AButton } from "../Vault/ReusableConponants/AButton/AButton";
import { Spinner, Form } from "react-bootstrap";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { sign } from "tweetnacl";
import { useFirebase } from "../Vault/Services/FirebaseService/FirebaseService";

export const VerifyPage = () => {
  const [responseCode, setResponseCode] = useState<any>(0);
  const [loading, setLoading] = useState<any>(false);
  const [isLedger, setIsLedger] = useState<any>(false);
  const { publicKey, signMessage } = useWallet();
  const [code, setCode] = useState<any>();
  const [params] = useSearchParams();
  const { customFirebaseRequest } = useFirebase();

  useEffect(() => {
    const codeQ = params.get("code");
    if (codeQ) {
      setCode(codeQ);
    } else {
      retry();
    }
  }, []);

  const sendToConfirmation = async (
    messageArray?: number[],
    signitureArray?: number[]
  ) => {
    // console.log({
    //     data: {
    //         messageArray,
    //         signitureArray,
    //         publicKeyAddress: publicKey?.toString(),
    //         code
    //     }
    // });
    setLoading(true);
    const res = await customFirebaseRequest("V5verifyDiscordVaultUser", {
      messageArray,
      isLedger,
      signitureArray,
      publicKeyAddress: publicKey?.toString(),
      code,
      route: "https://stake.diamondvaults.io/verify",
    });
    // const res = await axios.default.post("https://us-central1-nft-anybodies.cloudfunctions.net/V5addNewRolesForUser", {
    //     data: {
    //         messageArray,
    //         isLedger,
    //         signitureArray,
    //         publicKeyAddress: publicKey?.toString(),
    //         code
    //     }
    // })
    setResponseCode(res.code);
    setLoading(false);
    // console.log(res);
  };

  const requestSignMessage = async () => {
    if (isLedger) return await sendToConfirmation();
    //http://localhost:4200/verify?code=UQrLkn4t1RK89m3LcpSPErkviMMSCA
    let message = new TextEncoder().encode(`Verify your wallet address for: ${publicKey?.toString()}`);
    message = new Uint8Array(Array.from(message));
    // console.log(message.toString());
    try {
      // `publicKey` will be null if the wallet isn't connected
      if (!publicKey) throw new Error("Wallet not connected!");
      // `signMessage` will be undefined if the wallet doesn't support it
      if (!signMessage)
        throw new Error("Wallet does not support message signing!");

      // Encode anything as bytes

      // Sign the bytes using the wallet
      const signature = await signMessage(message);
      await sendToConfirmation(Array.from(message), Array.from(signature));
      // Verify that the bytes were signed using the private key that matches the known public key
      if (!sign.detached.verify(message, signature, publicKey.toBytes()))
        throw new Error("Invalid signature!");

      // console.log(`Message signature: ${bs58.encode(signature)}`, signature);
    } catch (error: any) {
      alert(`Signing failed: ${error?.message}`);
    }
  };


  const retry = () => {
    window.location.href =
      "https://discord.com/api/oauth2/authorize?client_id=915708397024854026&redirect_uri=https%3A%2F%2Fstake.diamondvaults.io%2Fverify&response_type=code&scope=identify";
  };

  const getReponseContent = () => {
    switch (responseCode) {
      case 0:
        return (
          <>
            <div style={{ marginTop: "20px" }}>
              <Form.Check
                type="checkbox"
                id={`default`}
                style={{ marginRight: "11px" }}
                onChange={(e: any) => setIsLedger(e.target.checked)}
                label={
                  <>
                    I am using a{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.ledger.com/"
                    >
                      Ledger
                    </a>
                  </>
                }
              />
              <AButton
                style={{ marginTop: "20px" }}
                onClick={requestSignMessage}
                disabled={loading}
              >
                {loading ? (
                  <div className="balls">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                ) : (
                  "CLICK HERE TO VERIFY"
                )}
              </AButton>
            </div>
          </>
        );
      case 123:
        return (
          <>
            <div style={{ display: "grid" }}>
              <div style={{ marginBottom: "20px" }}>
                We were unable to confirm this wallet.
              </div>
              <AButton onClick={retry} skin="light">
                Try again
              </AButton>
            </div>
          </>
        );

      case 777:
        return (
          <>
            <div className="title">SUCCESS!</div>
            <div className="success-container">
              <div className="verified">You've been verified!</div>
              <div className="verified-message">
                You can now earn new roles and rewards by staking in the vault.
              </div>
            </div>
          </>
        );
      case 321:
      default:
        return (
          <>
            <div style={{ display: "grid" }}>
              <div style={{ marginBottom: "20px" }}>
                {" "}
                We were unable to verify your identity.
              </div>
              <AButton onClick={retry} skin="light">
                Try again
              </AButton>
            </div>
          </>
        );
    }
  };
  return (
    <div className="verify-container">
      <div className="inner-container">
        {responseCode !== 777 &&
          responseCode !== 123 &&
          responseCode !== 321 &&
          loading === false && (
            <>
              <h1>VERIFY YOUR IDENTITY</h1>
              <p className="info-subtext">
                Verify your Discord account + Wallet to gain access
                <br />
                to the Vault roles and rewards
              </p>
            </>
          )}
        {loading && (
          <div className="balls">
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}

        {publicKey ? (
          <>{loading === false && getReponseContent()}</>
        ) : (
          <>
            <div>
              <WalletMultiButton className="wallet-btn" />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
