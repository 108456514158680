import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";
import { createContext, useContext, useEffect, useState } from "react";
import * as axios from "axios";

let app: firebase.app.App | undefined = firebase.initializeApp({
  apiKey: "AIzaSyABZVLQeN4r91nlyRHYww3Nr5yxblLRrfY",
  authDomain: "nft-anybodies.firebaseapp.com",
  projectId: "nft-anybodies",
  storageBucket: "nft-anybodies.appspot.com",
  messagingSenderId: "773321643955",
  appId: "1:773321643955:web:4d92166eafd8ab3958ddc6",
  measurementId: "G-QFKFEHZ0WL",
});
// console.log(firebase.apps.length);

// if (!firebase.apps.length) {
//     app = firebase.initializeApp({
//         apiKey: "AIzaSyABZVLQeN4r91nlyRHYww3Nr5yxblLRrfY",
//         authDomain: "nft-anybodies.firebaseapp.com",
//         projectId: "nft-anybodies",
//         storageBucket: "nft-anybodies.appspot.com",
//         messagingSenderId: "773321643955",
//         appId: "1:773321643955:web:4d92166eafd8ab3958ddc6",
//         measurementId: "G-QFKFEHZ0WL"
//     });
// } else {
//     app = firebase.app(); // if already initialized, use that one
// }
export const firestore = app.firestore();
firestore.settings({ experimentalForceLongPolling: true });
export const auth = app.auth();
export const storage = app.storage();
export const functions = app.functions("europe-west1");

type FirebaseFeatures = {
  firestore: firebase.firestore.Firestore;
  auth: firebase.auth.Auth;
  functions: firebase.functions.Functions;
  storage: firebase.storage.Storage;
  loadingUserState: boolean;
  user: firebase.User | null;
  customFirebaseRequest: (functionName: string, data: any) => Promise<any>;
  customFirebaseRequestEurope: (
    functionName: string,
    data: any
  ) => Promise<any>;
  signupWithTwitter: () => Promise<any>;
};

const signupWithTwitter = () =>
  new Promise<any>((resolve) => {
    const twitterProvider = new firebase.auth.TwitterAuthProvider();
    firebase.auth().useDeviceLanguage();
    firebase
      .auth()
      .signInWithPopup(twitterProvider)
      .then(async (result) => {
        /** @type {firebase.auth.OAuthCredential} */
        const credential: any = result.credential;

        // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
        // You can use these server side with your app's credentials to access the Twitter API.
        console.log(credential);

        const token = credential.accessToken;
        const secret = credential.secret;

        // The signed-in user info.
        const user = result.user;
        const userIdToken = await user?.getIdToken(true);
        await customFirebaseRequestEurope("SOCIAL_V1_CreateTwitterUser", {
          idToken: userIdToken,
          payload: {
            displayName: user?.displayName,
            token,
            secret,
          },
        });
        console.log({
          token,
          secret,
          user: user?.uid,
          name: user?.displayName,
          userIdToken,
        });
        resolve(true);
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        const credential = error.credential;
        resolve(false);
        // ...
      });
  });
export const customFirebaseRequest = (functionName: string, data: any = {}) =>
  new Promise<any>((resolve) => {
    // axios.default
    //   .post<any>(
    //     ["SOLANA_areTokensPNFT", "OCP_getMetadataKeys","OCP_isOCP"].includes(functionName)
    //       ? `https://us-central1-nft-anybodies.cloudfunctions.net/${functionName}`
    //       : `http://localhost:5000/nft-anybodies/us-central1/${functionName}`,
    //     {
    // axios.default
    //   .post<any>(
    //     `https://us-central1-nft-anybodies.cloudfunctions.net/${functionName}`,
    //     {
    axios.default
      .post<any>(
        `https://stake.diamondvaults.io/api/v1/staking/${functionName}`,
        {
          data,
        }
      )
      .then((response) => {
        // handle success
        // console.log("response", response.data);
        resolve(response.data);
      })
      .catch((error: any) => {
        // handle error
        console.log(error);
        return resolve(undefined);
      });
  });
const customFirebaseRequestEurope = (functionName: string, data: any = {}) =>
  new Promise<any>((resolve) => {
    // axios.default
    //   .post<any>(
    //     `http://localhost:5000/nft-anybodies/europe-west1/${functionName}`,
    //     {
    // axios.default
    //   .post<any>(
    //     `https://us-central1-nft-anybodies.cloudfunctions.net/${functionName}`,
    //     {
    axios.default
      .post<any>(
        `https://stake.diamondvaults.io/api/v1/staking/${functionName}`,
        {
          data,
        }
      )
      .then((response) => {
        // handle success
        // console.log("response", response.data);
        resolve(response.data);
      })
      .catch((error: any) => {
        // handle error
        console.log(error);
        return resolve(undefined);
      });
  });
const FirebaseContext = createContext<FirebaseFeatures>({
  firestore,
  auth,
  functions,
  storage,
  loadingUserState: true,
  user: null,
  customFirebaseRequest,
  customFirebaseRequestEurope,
  signupWithTwitter,
});

export function useFirebase() {
  return useContext(FirebaseContext);
}

export const FirebaseProvider: any = ({ children }: any) => {
  const [loadingUserState, setLoadingUserState] = useState(true);
  const [user, setUser] = useState<firebase.User | null>(null);
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoadingUserState(false);
    });
  }, []);

  const value: FirebaseFeatures = {
    firestore,
    auth,
    functions,
    storage,
    loadingUserState,
    user,
    customFirebaseRequest,
    customFirebaseRequestEurope,
    signupWithTwitter,
  };
  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
};
