import "./Hero.scss";
import { Slogen } from "./Slogen/Slogen";

export const Hero = () => {
  return (
    <div className="hero-main-container">
      <div className="slogen-con">
        <Slogen />
      </div>
    </div>
  );
};
