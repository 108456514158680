import React, { useContext, createContext, useEffect, useState } from "react";
import { ReactDimmer } from "react-dimmer";
import "./MultiplierStakingModalService.scss";
import { Spin as Hamburger } from "hamburger-react";
import { AButton } from "../../../ReusableConponants/AButton/AButton";


import CircularProgress from "@mui/material/CircularProgress";
import tickSVG from "../../../../../assets/images/tick.svg";
import { useFirebase } from "../../../Services/FirebaseService/FirebaseService";
import {
  MultiplierPlan,
  SelectMultiplierPlan,
} from "./SelectMultiplierPlan/SelectMultiplierPlan";
import { AMultiplierStakingButton } from "./AMultiplierStakingButton/AMultiplierStakingButton";
import { TokenData, useVault } from "../../../Services/VaultService/VaultService";
import { parseName } from "../../../../../utils/Web3Actions";

const modalContainer: any = {
  width: "100vw",
  height: "100vh",
  position: "fixed",
  zIndex: 50,
  display: "block",
  top: "0px",
  left: "0px",
  overflow: "auto",
};

export type StakeModalData = {
  onStakeSelected?: any;
  disableForm?: any;
};

export const MultiplierStakingModalService = createContext<StakeModalData>({});

export const useMultiplierStakingModal = () => {
  return useContext(MultiplierStakingModalService);
};

export const MultiplierStakingModalProvider = ({
  children,
  stakingProjectId,
}: any) => {
  const [dimmer, setDimmer] = useState<boolean>(false);

  useEffect(() => {
    document.body.style.overflowY = dimmer ? "hidden" : "auto";
  }, [dimmer]);

  const onBackdropSelected = (action: boolean) => {
    setDimmer(action);
  };

  const onStakeSelected = async () => {
    setDimmer(true);
  };

  const vaule: StakeModalData = {
    onStakeSelected,
  };

  return (
    <MultiplierStakingModalService.Provider value={vaule}>
      {children}
      <ReactDimmer
        zIndex={4}
        opacity={0.8}
        blur={15}
        transition={0.2}
        isOpen={dimmer}
        exitDimmer={onBackdropSelected}
      />
      {dimmer && (
        <div style={modalContainer}>
          <StakeForm closeModal={() => setDimmer(false)} />
          <div className="close-overlay-btn">
            <Hamburger
              hideOutline={true}
              color="#ffdd02"
              toggled={dimmer}
              toggle={setDimmer}
            />
          </div>
        </div>
      )}
    </MultiplierStakingModalService.Provider>
  );
};

const MAX = 10;

type ItemSelect = {
  token: TokenData;
  selected: boolean;
};

const StakeForm = ({ closeModal }: any) => {
  const {
    busyStakingTokenAddress,
    unstakedItems,
    stakedItems,
    stakingProjectData: { VaultId, ProjectId },
    loadingUnstaked,
  } = useVault();
  const { firestore } = useFirebase();
  const [stakingPlans, setStakingPlans] = useState<any>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setupPlans();
  }, []);
  useEffect(() => {
    if (stakingPlans) {
      // if (stakingPlans?.length === 1) {
      //   onSelectedPlan(stakingPlans[0]);
      // }
    }
  }, [stakingPlans]);

  const setupPlans = async () => {
    setLoading(true);
    const { docs } = await firestore
      .collection(
        `/AVaultProject/${ProjectId}/AVault/${VaultId}/AMultiplierProgram`
      )
      .where("Active", "==", true)
      .get();
    console.log(docs.length);

    setStakingPlans(docs);
    setLoading(false);
  };

  const [items, setItems] = useState<ItemSelect[]>([]);
  const [supportedCollections, setSupportedCollections] = useState<any>(null);
  const [selectedPlan, onSelectedPlan] = useState<any>();
  const [isBusy, setIsBusy] = useState(false);
  const [loadingSupportedCollection, setLoadingSupportedCollection] =
    useState(false);

  useEffect(() => {
    // if (selectedPlan?.RewardProgram === "collection") {
    if (selectedPlan) {
      setLoadingSupportedCollection(true);
      console.log(selectedPlan);

      const { ProjectId, VaultId } = selectedPlan.data();
      firestore
        .collection(
          `/AVaultProject/${ProjectId}/AVault/${VaultId}/AMultiplierProgram/${selectedPlan.id}/AMultiplierStakingCollection`
        )
        .get()
        .then((res) => {
          const supportedTokens = res.docs
            .map((doc) => doc.data().address)
            .flat();
          setSupportedCollections(supportedTokens);
          setLoadingSupportedCollection(false);
        })
        .catch((err) => {
          console.error("Get supported collection err", err);
          setSupportedCollections([]);
          setLoadingSupportedCollection(false);
        });
    }
    // } else {
    //   setLoadingSupportedCollection(false);
    // }
  }, [selectedPlan]);

  useEffect(() => {
    if (loadingSupportedCollection === false) {
      const parseItems = unstakedItems!
        .filter(
          (item) =>
            stakedItems?.find(
              (stakedItem) => stakedItem.mintAddress === item.mintAddress
            ) === undefined
        )
        .filter(
          (item) =>
            busyStakingTokenAddress!.includes(item.mintAddress) === false
        )
        .filter((item) => {
          if (supportedCollections) {
            return supportedCollections!.includes(item.mintAddress) === true;
          }
          return true;
        })
        .map((item) => ({
          token: item,
          selected: false,
        }));
      setItems([...parseItems]);
    } else {
      setItems([]);
    }
  }, [
    supportedCollections,
    loadingSupportedCollection,
    busyStakingTokenAddress,
    unstakedItems,
  ]);

  const setItem = (index: number) => {
    if (isBusy === false) {
      const arr: ItemSelect[] = [...items];
      arr[index].selected =
        arr[index].selected === true ? false : checkIsMaxed() ? false : true;
      setItems(arr);
    }
  };

  const selectMax = () => {
    const arr: ItemSelect[] = [...items];
    for (let index = 0; index < arr.length; index++) {
      arr[index].selected = index < MAX;
    }
    setItems(arr);
  };

  const getSelected = () =>
    items
      .filter((items) => items.selected)
      .map((item) => item.token.mintAddress);

  const checkIsMaxed = () =>
    items.filter((i) => i.selected === true).length >= MAX;

  const getNumberFromName = (text: string) => {
    return parseName(text|| "") || "";
   
  };

  return (
    <>
      <div className="multiplier-stake-container">
        {selectedPlan && (
          <>
            <div className="title">ADD A MULTIPLIER</div>
            <div className="select-plan-main-container">
              <MultiplierPlan
                isBusy={isBusy}
                planDoc={selectedPlan}
                onChangePlan={() => onSelectedPlan(null)}
              />
            </div>
            {items.length > 0 && (
              <>
                <div className="explainer">
                  You can select up to {MAX} items at once
                </div>
                <AButton
                  style={{ marginTop: "30px" }}
                  onClick={selectMax}
                  skin="light"
                >
                  Select Max
                </AButton>
              </>
            )}
            {loadingUnstaked && (
              <div className="vault-items-section-container">
                <CircularProgress color="inherit" />
              </div>
            )}
            {loadingSupportedCollection && <CircularProgress color="inherit" />}

            {!loadingSupportedCollection &&
              !loadingUnstaked &&
              items.length == 0 && (
                <>
                  <div className="no-items">
                    You don't have any items available for staking...
                  </div>
                </>
              )}

            <div
              style={{
                opacity: isBusy ? 0.6 : 1,
              }}
              className="form-container"
            >
              {items.length > 0 &&
                items.map((i, index) => {
                  return (
                    <div
                      onClick={() => setItem(index)}
                      className="form-item-container"
                    >
                      <div className="form-title">
                        {getNumberFromName(i.token.tokenData.name)}
                      </div>
                      <div className="form-item-inner-container">
                        <div
                          className={
                            i.selected === false
                              ? "form-item"
                              : "form-item-selected"
                          }
                          style={{
                            opacity:
                              i.selected === true && checkIsMaxed() ? 0.3 : 1,
                            background: `url('${i.token.tokenData.image}') center center`,
                            backgroundSize: "contain",
                          }}
                        >
                          {i.selected === true && (
                            <div className="selected-container noselect">
                              <img className="tick" src={tickSVG} alt="" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        )}
        {!selectedPlan && (
          <>
            <div className="title">STAKE MULTIPLIER</div>
            <div className="explainer">Increase the daily reward for your staked tokens by adding a multiplier</div>
            <SelectMultiplierPlan  onSelectedPlan={onSelectedPlan} />
          </>
        )}
        {selectedPlan && (
          <>
            <AMultiplierStakingButton
              selectedPlan={selectedPlan}
              closeModal={closeModal}
              handleBusy={setIsBusy}
              mintAddress={getSelected()}
            />
          </>
        )}
      </div>
    </>
  );
};
