import "./MultiplierItem.scss";
import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { AButton } from "../../../ReusableConponants/AButton/AButton";
import { useMultiplierInfoModal } from "../MultiplierInfoModalService/MultiplierInfoModalService";
import { useVault } from "../../../Services/VaultService/VaultService";
import moment from "moment";

export const MultiplierItem: React.FC<any> = ({
  itemData,
  itemDoc,
}: {
  itemData: any;
  itemDoc: any;
}) => {
  const { stakingProjectData } = useVault();
  const { onStakeSelected } = useMultiplierInfoModal();

  const [percent, setPercent] = useState<any>();
  useEffect(() => {
    (() => {
      const start = itemData.created.toDate()!.getTime();
      const end = itemData.expirationDate.toDate()!.getTime();
      const now = Date.now();
      const p = 100 - ((now - start) / (end - start)) * 100;
      setPercent(p <= 0 ? 0 : p);
    })();
    const interval = setInterval(() => {
      const start = itemData.created!.toDate().getTime();
      const end = itemData.expirationDate!.toDate().getTime();
      const now = Date.now();
      const p = 100 - ((now - start) / (end - start)) * 100;

      setPercent(p <= 0 ? 0 : p);
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  // const getDays = () => {
  //     const now = moment();
  //     const staked = moment(itemData.created);
  //     const daysLeft = selectedVaultData.StakeDurationDays - now.diff(staked, 'days')
  //     return `${daysLeft} days left`
  // }

  // const getPercent = () => {
  //     const now = moment();
  //     const staked = moment(itemData.created);
  //     const daysLeft = selectedVaultData.StakeDurationDays - now.diff(staked, 'days')
  //     return (daysLeft / selectedVaultData.StakeDurationDays) * 100
  // }

  const handleMultiplierSelected = () => {
    onStakeSelected(itemDoc);
  };

  const getStakingProgram = (StakingProgram: string) => {
    console.log(StakingProgram);

    switch (StakingProgram) {
      case "duration":
        return "LOCKED";
      case "unstake":
        return "CAN UNSTAKE";
      case "non-custodial":
        return "NON-CUSTODIAL";

      default:
        break;
    }
  };
  const getLimitProgram = (LimitPlan: string) => {
    switch (LimitPlan) {
      case "all":
        return (
          <b
            style={{
              color: "#ffdd07",
              fontSize: "13px",
            }}
          >
            UNLIMITED
          </b>
        );
      case "limit":
        return (
          <div>
            <div className="in-use"> In Use</div>
            <b
              style={{
                color: itemData.ActiveNumber > 0 ? "#ffdd07" : "",
                fontSize: "15px",
              }}
            >
              {itemData.ActiveNumber}
            </b>
            /{itemData.MaxUsable}
          </div>
        );
      default:
        return "";
    }
  };

  return (
    <>
      {stakingProjectData && itemData && (
        <div
          className="vault-multiplier-item-container"
          onClick={handleMultiplierSelected}
        >
          <div className="image-container">
            <img
              className="image"
              src={itemData?.manifest?.image}
              alt="image"
            />
            {itemData.busyStaking && (
              <>
                <div className="busy-container">
                  <div className="busy-loader"></div>
                </div>
              </>
            )}
          </div>
          <div className="staked-container">
            {moment().isBefore(itemData.expirationDate.toDate()) && (
              <>
                <div className="staked-info-container">
                  <div className="staked-program-info-section">
                    {getStakingProgram(itemData.StakingProgram)}
                  </div>
                  <div className="divider"></div>
                  <div className="staked-info-section">
                    {getLimitProgram(itemData.LimitPlan)}
                  </div>
                  <div className="divider"></div>

                  {/* <div className="staked-info-section">
                <div className="time-left">{itemData.ActiveNumber}/5</div>
              </div> */}
                </div>
              </>
            )}
            {percent === 0 && (
              <div className="busy-unstaking">BUSY UNSTAKING</div>
            )}
            {percent > 0 && <progress value={percent} max="100"></progress>}
            {percent > 0 && <div className="activate">EDIT</div>}
          </div>
        </div>
      )}
    </>
  );
};

const Completionist = () => (
  <span style={{ fontSize: "13px" }}>BUSY UNSTAKING</span>
);

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
  if (completed) {
    // Render a complete state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <div>
        Time left
        <div className="items-container">
          <div className="item-container">
            <div className="item-value">{days}</div>
            <div className="item-title">D</div>
          </div>
          <div className="divider"></div>
          <div className="item-container">
            <div className="item-value">{hours}</div>
            <div className="item-title">H</div>
          </div>
          <div className="divider"></div>
          <div className="item-container">
            <div className="item-value">{minutes}</div>
            <div className="item-title">M</div>
          </div>
        </div>
      </div>
    );
  }
};
