import React, { useEffect } from "react";
import { useVault } from "../../Services/VaultService/VaultService";
import './VaultInfoSection.scss'

export const VaultInfoSection: React.FC<any> = () => {
    const { stakingProjectData } = useVault();
    return <>
        {stakingProjectData && stakingProjectData.ImageURL && (<>
            <div className="vault-info-section">
                <img style={{ width: `${stakingProjectData.ImageWidth}px`, maxWidth: '80vw' }} className="preview-image" src={stakingProjectData.ImageURL} alt="" />
                {/* <div>{stakingProjectData.Name}</div> */}
            </div>
        </>)}
    </>
}    