/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-async-promise-executor */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useWallet } from "@solana/wallet-adapter-react";
import { AButton } from "../AButton/AButton";
import "./AStakingButton.scss";
import {
  anybodiesConnection,
  createLocalTransaction,
  SerializedTransaction,
  serializeTransaction,
} from "./StakingButtontUtils";
import { useToasts } from "react-toast-notifications";
import { useState } from "react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import {
  Connection,
  LAMPORTS_PER_SOL,
  PublicKey,
  Transaction,
  TransactionInstruction,
} from "@solana/web3.js";
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  Token,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import { useFirebase } from "../../Services/FirebaseService/FirebaseService";
import { sendSignedTransaction } from "../../../../connection";
import { useVault } from "../../Services/VaultService/VaultService";
import * as axios from "axios";

export const AStakingButton: React.FC<{
  closeModal: any;
  handleBusy: any;
  mintAddress: string[];
  selectedPlan: any;
}> = ({ closeModal, handleBusy, mintAddress, selectedPlan }) => {
  const { addToast } = useToasts();
  const {
    vaultId,
    stakingProjectData,
    vaultConfig: { ClientRPC },
  } = useVault();
  const { firestore, customFirebaseRequest } = useFirebase();
  const [busy, setBusy] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(0.1);
  const { publicKey, signTransaction, signAllTransactions } = useWallet();

  const getMessage = () => {
    switch (currentStatus) {
      case 0.1:
        return "Creating request...";
      case 0:
        return "Confirming your transaction...";
      case 1:
        return "Staking your tokens";
      default:
        return "Busy...";
    }
  };

  const handleStakingRequest = async () => {
    if (clicked === true) return;
    if (signTransaction && publicKey && signAllTransactions) {
      setBusy(true);
      handleBusy(true);
      setCurrentStatus(0.1);
      const {
        data: { data },
      } = await axios.default.post(
        "http://localhost:5000/nft-anybodies/us-central1/SUBS_getSubscriptionRequest",
        {
          data: {
            accountId: selectedPlan.ProjectId,
            communityId: vaultId,
            tokens: mintAddress.map((address) => ({
              contractId: selectedPlan.AStakingProgramId,
              mintAddress: address,
            })),
            walletAddress: publicKey.toString(),
            type: "subsciption",
          },
        }
      );
      console.info(data);

      if (!data.success) {
        addToast(data.error.title, {
          appearance: "warning",
          autoDismiss: true,
        });
        setBusy(false);
        handleBusy(false);
        closeModal();
      }
      const { transactionRequests } = data;
      const transactions = transactionRequests.map(
        ({ rawTransaction }: any) => {
          return Transaction.from(rawTransaction.data);
        }
      );
      try {
        const signedTransactions = await signAllTransactions(transactions);
        const payload = signedTransactions.map((transaction, index) => {
          const buf = transaction.serialize({
            requireAllSignatures: false,
            verifySignatures: false,
          });
          return {
            rawTransaction: Array.from(buf),
            requestId: transactionRequests[index].requestId,
          };
        });
        console.log(payload);
        const {
          data: { data: processData },
        } = await axios.default.post(
          "http://localhost:5000/nft-anybodies/us-central1/SUBS_processSubscriptionRequest",
          {
            data: {
              transactionRequests: payload,
            },
          }
        );
        console.info({ processData });
        setBusy(false);
        handleBusy(false);
        closeModal();
      } catch (err) {
        console.log("User rejected the transaction.");
        setBusy(false);
        handleBusy(false);
        closeModal();
      }
    }
  };

  const handleStakingRequestOld = async () => {
    if (clicked === true) return;
    if (signTransaction && publicKey) {
      setBusy(true);
      handleBusy(true);
      setCurrentStatus(0.1);

      try {
        console.log(stakingProjectData.VaultAddress);
        const { data: pnftMap } = await customFirebaseRequest(
          "SOLANA_areTokensPNFT",
          mintAddress
        );
        const { success, vaultStakingRequest, errorCode, message } =
          await createTransactionRequest(
            stakingProjectData.VaultAddress,
            pnftMap
          );
        if (success) {
          console.log(
            `/AVaultProject/${selectedPlan.ProjectId}/AVault/${vaultId}/AVaultStakingRequest/${vaultStakingRequest}`
          );

          const { LocalTransactionData } = (
            await firestore
              .doc(
                `/AVaultProject/${selectedPlan.ProjectId}/AVault/${vaultId}/AVaultStakingRequest/${vaultStakingRequest}`
              )
              .get()
          ).data()!;
          const createTransactions = () =>
            createLocalTransaction(LocalTransactionData, pnftMap);
          const showToast = () =>
            addToast(
              "Oh ho, something went wrong... Trying again in 5 seconds",
              { appearance: "warning", autoDismiss: true }
            );

          let balance = await anybodiesConnection.getBalance(publicKey);
          balance = balance / LAMPORTS_PER_SOL;
          const transactionFee = 0.006 * mintAddress.length + 0.0001;
          if (balance < transactionFee) {
            addToast(
              <>
                You don't have enough $SOL to stake.
                <br />
                <br />
                Estimated ${transactionFee} $SOL required to stake.
                <br />
              </>,
              { appearance: "warning" }
            );
            setBusy(false);
            handleBusy(false);
            return;
          }
          setCurrentStatus(0);
          const transaction = await signAndReturnTransaction(
            createTransactions,
            showToast
          );

          if (transaction) {
            setCurrentStatus(0);
            processVaultTransactionRequest(
              vaultStakingRequest,
              serializeTransaction(transaction),
              vaultId!,
              transaction
              // txid,
            )
              .then((res) => {
                if (res) {
                  tryConfirmTransaction(transaction, ClientRPC)
                    .then((txid) => {
                      if (txid) {
                        addToast(
                          <p>
                            <b>Your staking request was successfully sent!</b>
                            <br />
                            Your NFTs will be staked soon
                          </p>,
                          { appearance: "success" }
                        );
                        // addToast(
                        //   <p>
                        //     Due to the recent Solana congestions, this may take
                        //     longer than usual.
                        //   </p>,
                        //   { appearance: "info" }
                        // );
                        setBusy(false);
                        handleBusy(false);
                        closeModal();
                      } else {
                        addToast(
                          <p>
                            <b>
                              Unable to confirm transaction after 120 seconds...
                            </b>
                            <br />
                            This is probably due to Solana congestion.
                            <br />
                            <br />
                            Please try again.
                          </p>,
                          { appearance: "error" }
                        );
                        setCurrentStatus(404);
                        setBusy(false);
                        handleBusy(false);
                        closeModal();
                      }
                    })
                    .catch();
                } else {
                  addToast(
                    <p>
                      <b>Unable to confirm transaction after 120 seconds...</b>
                      <br />
                      This is probably due to Solana congestion.
                      <br />
                      <br />
                      Please try again.
                    </p>,
                    { appearance: "error" }
                  );
                  setCurrentStatus(404);
                  setBusy(false);
                  handleBusy(false);
                  closeModal();
                }
              })
              .catch((err) => {
                console.error("Staking process ERR:", err);
                addToast("Oh ho, something went wrong... contact the team.", {
                  appearance: "error",
                });
                setBusy(false);
                handleBusy(false);
              });
            // if (success) {

            // if (txid) {

            // } else {
            //   addToast(
            //     `Oh ho, Solana is congested, failed to send transaction. please try again in 5 minutes.`,
            //     { appearance: 'error' },
            //   );
            //   setCurrentStatus(404);
            // }
          } else {
            setBusy(false);
            handleBusy(false);
          }
        } else {
          if (errorCode !== 12) {
            console.error({ errorCode });

            if (
              message ===
              "You have selected tokens that are not supported for this staking plan."
            ) {
              addToast(
                "You are trying to stake NFTs that are not supported in this selected staking plan.",
                {
                  appearance: "error",
                }
              );
            } else {
              addToast("Oh ho, something went wrong... contact the team.", {
                appearance: "error",
              });
            }
          }
          setBusy(false);
          handleBusy(false);
        }
      } catch {
        setBusy(false);
        handleBusy(false);
      }
    }
  };

  const createTransactionRequest = (vaultAddress: string, pnftMap: any) =>
    new Promise<any>(async (resolve) => {
      try {
        const createdATA = await getATA(
          vaultAddress,
          mintAddress.filter((tokenAddress) => pnftMap[tokenAddress] === 0),
          addToast,
          publicKey!
        );
        if (createdATA === false)
          return resolve({
            success: false,
            errorCode: 12,
          });
        await sleep(3000);
        customFirebaseRequest(`V5User_handleRequestStaking`, {
          projectId: selectedPlan.ProjectId,
          vaultId,
          planId: selectedPlan.AStakingProgramId,
          walletAddress: publicKey?.toString(),
          tokenAddress: mintAddress,
        })
          .then((response) => {
            // handle success
            // console.log("response", response.data);
            resolve(response);
          })
          .catch((error: any) => {
            // handle error
            console.log(error);
            return resolve({
              success: false,
            });
          });
      } catch (error: any) {
        // handle error
        console.log(error);
        return resolve({
          success: false,
        });
      }
    });

  const getATA = async (
    vaultAdress: string,
    mintAddresss: string[],
    addToast: any,
    publicKey: PublicKey,
    attempt = 0,
    maxAttempts = 1
  ): Promise<boolean> => {
    const privateConnection = new Connection(ClientRPC);
    const ownerPublicKey = publicKey;
    const vaultPublicKey = new PublicKey(vaultAdress)!;
    const instructions: TransactionInstruction[] = [];
    const nonOCP: string[] = [];
    console.log({ mintAddresss });

    for (const tokenAddress of mintAddresss) {
      const {
        data: { OCP },
      }: any = await customFirebaseRequest("OCP_isOCP", {
        tokenAddress,
      });
      console.log("isOCP.data", OCP);
      if (OCP === false) nonOCP.push(tokenAddress);
    }

    console.log({ nonOCP });

    //   Create user ATA
    await Promise.all(
      nonOCP.map(async (mintAddress) => {
        const mintPublicKey = new PublicKey(mintAddress);
        const associatedDestinationTokenAddr =
          await Token.getAssociatedTokenAddress(
            ASSOCIATED_TOKEN_PROGRAM_ID,
            TOKEN_PROGRAM_ID,
            mintPublicKey,
            ownerPublicKey
          );

        const res: any = await privateConnection.getTokenLargestAccounts(
          mintPublicKey
        );
        const receiverAccount = await privateConnection.getAccountInfo(
          associatedDestinationTokenAddr
        );

        console.log(
          "Staker associatedDestinationTokenAddr",
          associatedDestinationTokenAddr.toString()
        );
        console.log("Staker receiverAccount", receiverAccount);
        if (receiverAccount === null) {
          instructions.push(
            Token.createAssociatedTokenAccountInstruction(
              ASSOCIATED_TOKEN_PROGRAM_ID,
              TOKEN_PROGRAM_ID,
              mintPublicKey,
              associatedDestinationTokenAddr,
              ownerPublicKey,
              ownerPublicKey
            )
          );
        }

        const array: any = res.value.map((info: any) => +info.amount);
        const index: any = array.indexOf(Math.max(...array));
        const sourceTokenAccount = res.value[index].address;
        console.log({
          sourceTokenAccount: sourceTokenAccount.toString(),
          associatedDestinationTokenAddr:
            associatedDestinationTokenAddr.toString(),
        });

        if (
          sourceTokenAccount.toString() !==
          associatedDestinationTokenAddr.toString()
        ) {
          // console.log('Staker transfer', receiverAccount);
          instructions.push(
            Token.createTransferInstruction(
              TOKEN_PROGRAM_ID,
              sourceTokenAccount,
              associatedDestinationTokenAddr,
              ownerPublicKey,
              [],
              1
            )
          );
        }
      })
    );

    //   Create vault ATA
    if (selectedPlan.StakingProgram !== "non-custodial")
      await Promise.all(
        nonOCP.map(async (mintAddress) => {
          const mintPublicKey = new PublicKey(mintAddress);
          const associatedDestinationTokenAddr =
            await Token.getAssociatedTokenAddress(
              ASSOCIATED_TOKEN_PROGRAM_ID,
              TOKEN_PROGRAM_ID,
              mintPublicKey,
              vaultPublicKey
            );
          const receiverAccount = await privateConnection.getAccountInfo(
            associatedDestinationTokenAddr
          );
          console.log("Vault", receiverAccount);

          if (receiverAccount === null) {
            instructions.push(
              Token.createAssociatedTokenAccountInstruction(
                ASSOCIATED_TOKEN_PROGRAM_ID,
                TOKEN_PROGRAM_ID,
                mintPublicKey,
                associatedDestinationTokenAddr,
                vaultPublicKey,
                ownerPublicKey
              )
            );
          }
        })
      );

    if (instructions.length > 0) {
      let balance = await anybodiesConnection.getBalance(ownerPublicKey);
      balance = balance / LAMPORTS_PER_SOL;
      const costEst = instructions.length * 0.0025;
      if (balance < costEst) {
        addToast(
          <>
            You don't have enough $SOL to migrate your tokens to your
            "Associated Token Account".
            <br />
            <br />
            <b>Estimated cost is ${costEst} $SOL.</b>
            <br />
            <br />
            <a
              href="https://spl.solana.com/associated-token-account"
              target="_blank"
              rel="noreferrer"
            >
              Click here for more info about "Associated Token Account"
            </a>
          </>,
          { appearance: "warning" }
        );
        return false;
      } else {
        try {
          const transaction = new Transaction().add(...instructions);
          transaction.recentBlockhash = (
            await privateConnection.getLatestBlockhash()
          ).blockhash;
          transaction.feePayer = ownerPublicKey;
          const finalTransaction = await signTransaction!(transaction);
          // const finalTransaction = await w.solana.signTransaction(transaction);
          const txid = await sendSignedTransaction({
            connection: privateConnection,
            signedTransaction: finalTransaction,
          });
          if (txid) {
            return true;
          } else {
            if (attempt < maxAttempts) {
              return await getATA(
                vaultAdress,
                mintAddresss,
                addToast,
                publicKey,
                attempt + 1
              );
            }
          }
        } catch {
          if (attempt >= maxAttempts) {
            return false;
          }
          return await getATA(
            vaultAdress,
            mintAddresss,
            addToast,
            publicKey,
            attempt + 1
          );
        }
      }
    }

    return true;
  };

  const signAndReturnTransaction = (
    createTransactions: any,
    showToast: any,
    attempt = 0,
    maxAttempts = 1
  ) =>
    new Promise<Transaction | undefined>(async (resolve) => {
      if (attempt >= maxAttempts) return resolve(undefined);
      try {
        const signedTransation = await signTransaction!(
          await createTransactions()
        );

        resolve(signedTransation);
        return signedTransation;
      } catch (err) {
        console.log(err);
        showToast();
        await sleep(5000);
        resolve(
          await signAndReturnTransaction(
            createTransactions,
            showToast,
            attempt + 1
          )
        );
      }
    });

  const processVaultTransactionRequest = (
    transactionRequestId: string,
    serializedTransaction: SerializedTransaction,
    vaultId: string,
    transaction: Transaction
    // txid: string,
  ) =>
    new Promise<any>((resolve) => {
      customFirebaseRequest("V6_requestHandleStakeQueueRequest", {
        projectId: selectedPlan.ProjectId,
        vaultId,
        transactionRequestId,
        trans: transaction.serialize(),
        transactionRequestPayload: serializedTransaction,
        // txid,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error: any) => {
          console.log(error);
          resolve(false);
        });
    });

  if (busy === true) {
    return (
      <>
        {mintAddress.length > 0 && (
          <div className="minting-button-main-container">
            <div className="minting-button-loader"></div>
            <div className="message">{getMessage()}</div>
            <div className="may-take">
              May take a moment, trust the process!
            </div>
          </div>
        )}
      </>
    );
  } else {
    return (
      <>
        {mintAddress.length > 0 && (
          <div className="minting-button-main-container">
            {publicKey && (
              <AButton
                disabled={mintAddress.length === 0}
                style={{
                  width: "fit-content",
                  margin: "auto",
                }}
                onClick={handleStakingRequest}
              >
                STAKE
              </AButton>
            )}
            {!publicKey && <WalletMultiButton className="wallet-btn" />}
          </div>
        )}
      </>
    );
  }
};

const tryConfirmTransaction = async (
  finalTransaction: Transaction,
  RPC: string
) => {
  const connection = new Connection(RPC);
  try {
    const txid = await sendSignedTransaction({
      connection: connection,
      signedTransaction: finalTransaction,
    });
    if (txid) {
      return txid;
    } else {
      return undefined;
    }
  } catch (err) {
    return undefined;
  }
};

const sleep = (timeout = 2000) =>
  new Promise<any>((resolve) => setTimeout(resolve, timeout));
