import { createContext, useContext, useEffect, useState } from "react"



interface ScreenSizePayload {
    screenWidth: number;
    screenHeight: number;
}

const ScreenSizeContext = createContext<ScreenSizePayload>(
    {
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight
    }
);

export const useScreenSizeService = () => useContext(ScreenSizeContext)

export const ScreenSizeProvider = ({ children }: any) => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    useEffect(() => {
        const changeDimensions = () => {
            setScreenWidth(window.innerWidth);
            setScreenHeight(window.innerHeight);
        }
        window.addEventListener('resize', changeDimensions)
        return () => {
            window.removeEventListener('resize', changeDimensions)
        }
    }, [])

    const value: ScreenSizePayload = {
        screenWidth,
        screenHeight
    }

    return (
        <ScreenSizeContext.Provider value={value}>
            {children}
        </ScreenSizeContext.Provider>
    )

}