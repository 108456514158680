import "./SelectMultiplierPlan.scss";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { useFirebase } from "../../../../Services/FirebaseService/FirebaseService";
import { CircularProgress } from "@mui/material";
import { useVault } from "../../../../Services/VaultService/VaultService";

export const SelectMultiplierPlan: React.FC<any> = ({ onSelectedPlan }) => {
  const {
    stakingProjectData: { VaultId, ProjectId },
  } = useVault();
  const { firestore } = useFirebase();
  const [stakingPlans, setStakingPlans] = useState<any>();

  useEffect(() => {
    setupPlans();
  }, []);

  const setupPlans = async () => {
    const { docs } = await firestore
      .collection(
        `/AVaultProject/${ProjectId}/AVault/${VaultId}/AMultiplierProgram`
      )
      .where("Active", "==", true)
      .get();
    console.log(docs.length);

    setStakingPlans([...docs]);
  };

  return (
    <>
      {stakingPlans === undefined && (
        <CircularProgress
          style={{ margin: "auto", marginTop: "20px" }}
          color="inherit"
        />
      )}
      <div className="select-multiplier-plan-main-container">
        {stakingPlans?.map((plan: any) => {
          return (
            <>
            <MultiplierPlan planDoc={plan} onSelectedPlan={onSelectedPlan} />
            </>
          );
        })}
      </div>
    </>
  );
};

export const MultiplierPlan: React.FC<any> = ({
  onSelectedPlan,
  planDoc,
  onChangePlan,
  isBusy,
}) => {
  const getStakingPlan = (RewardProgram: string) => {
    switch (RewardProgram) {
      case "duration":
        return "LOCKED";
      case "non-custodial":
        return "NON-CUSTODIAL";
      case "unstake":
        return "NO LOCK";
      default:
        return "N/A";
    }
  };
  if (planDoc) {
    const { PlanTitle, PlanDescription, StakingDuration, StakingProgram } =
      planDoc.data();
    return (
      <>
        <div className="plan-container">
          <div>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid style={{ width: "100%" }} item xs>
                  <Typography
                    className="line-break"
                    gutterBottom
                    variant="subtitle1"
                    component="div"
                  >
                    {PlanTitle}
                  </Typography>
                  <Typography
                    className="line-break"
                    variant="body2"
                    gutterBottom
                  >
                    {PlanDescription}
                  </Typography>
                  <Stack
                    style={{ marginTop: "10px" }}
                    direction="row"
                    spacing={1}
                  >
                    <Chip label={`${StakingDuration} days`} color="secondary" />
                    <Chip
                      label={getStakingPlan(StakingProgram)}
                      variant={
                        StakingProgram === "duration" ? "outlined" : "filled"
                      }
                      color={
                        StakingProgram === "duration" ? "warning" : "primary"
                      }
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </div>
          {onSelectedPlan && (
            <Button
              onClick={() => onSelectedPlan(planDoc)}
              style={{ marginTop: "30px", color: "#eccd0e" }}
              size="large"
              color="inherit"
            >
              SELECT
            </Button>
          )}
          {onChangePlan && (
            <Button
              disabled={isBusy}
              onClick={() => onChangePlan(planDoc)}
              style={{ marginTop: "30px", color: "#eccd0e" }}
              size="large"
              color="inherit"
            >
              CHANGE
            </Button>
          )}
        </div>
      </>
    );
  }
  return <></>;
};
