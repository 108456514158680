/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-async-promise-executor */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import React, { useEffect, useState } from "react";
import "./VaultBalance.scss";
import Button from "@mui/material/Button";
import { useWallet } from "@solana/wallet-adapter-react";
import { LAMPORTS_PER_SOL, PublicKey, Transaction } from "@solana/web3.js";
import { useToasts } from "react-toast-notifications";
import { useFirebase } from "../../Services/FirebaseService/FirebaseService";
import { createLocalSolTransactionWithRewardATA } from "../../../../utils/Web3Actions/SolTransfer";
import { tryConfirmTransactionOfficial } from "../../../../utils/Web3Actions";
import { CircularProgress } from "@mui/material";
import * as axios from "axios";
import {
  anybodiesConnection,
  SerializedTransaction,
  serializeTransaction,
} from "../../ReusableConponants/AStakingButton/StakingButtontUtils";
import { useVault } from "../../Services/VaultService/VaultService";


export const VaultBalance: React.FC<any> = () => {
  const { customFirebaseRequest } = useFirebase();
  const { publicKey, signTransaction } = useWallet();
  const { addToast } = useToasts();
  const [balance, setBalance] = useState<any>();
  const [loading, setLoading] = useState<any>(false);
  const [tokenList, setTokenList] = useState<any>();
  const [rewardPool, setRewardPool] = useState<any>();
  const {
    vaultId,
    stakedDocs,
    stakingProjectData,
    withdrawingDocs,
    hideWithdraw,
    vaultConfig: { ClientRPC },
  } = useVault();

  useEffect(() => {
    if (stakedDocs) {
      const rewardSet: any = new Set(
        stakedDocs.map((doc: any) => doc.data().TokenData.RewardTokenAddress)
      );
      const rewardTokens: any = [...rewardSet];
      setTokenList(rewardTokens);

      const interval = setInterval(() => {
        const map: any = {};
        for (const doc of stakedDocs) {
          const {
            redeemed,
            expirationDate,
            dailyReward,
            TokenId,
            StakingDuration,
            created,
            MultiplierInfo,
            AccumulatedBonusRewards,
            TokenData: { TokenName, RewardTokenAddress },
          } = doc.data();
          if (!map[TokenName]) {
            rewardTokens.push(RewardTokenAddress);
            map[TokenName] = {
              TokenId,
              amount: 0,
              RewardTokenAddress,
              dailyReward,
            };
          }
          let duration: any;
          if (moment().isBefore(moment(expirationDate.toDate()))) {
            duration = moment.duration(moment().diff(moment(created.toDate())));
          } else {
            duration = moment.duration(
              moment(expirationDate.toDate()).diff(moment(created.toDate()))
            );
          }
          const minutes = duration.asMinutes();
          const minuteReward = dailyReward / 24 / 60;

          const accumilatedReward = minuteReward * minutes - redeemed;
          const max = dailyReward * StakingDuration;
          map[TokenName].amount +=
            accumilatedReward > max ? max : accumilatedReward;
          // console.log(accumilatedReward > max ? max : accumilatedReward);

          if (AccumulatedBonusRewards > 0) {
            map[TokenName].amount += AccumulatedBonusRewards || 0;
          }

          // Add multiplier bonus
          if (MultiplierInfo) {
            map[TokenName].amount += getMultiplerBonusReward(
              dailyReward,
              MultiplierInfo,
              expirationDate.toDate()
            );
          }
        }
        setBalance(map);
      }, 1000);
      return () => clearInterval(interval);
    }
    return;
  }, [stakedDocs]);

  const getMultiplerBonusReward = (
    dailyReward: number,
    multiplierInfo: any,
    expirationDate: Date
  ) => {
    const { Created, MultiplierDaliyReward, MultiplierAction } = multiplierInfo;
    let duration: any;
    if (moment().isBefore(moment(expirationDate))) {
      duration = moment.duration(moment().diff(moment(Created.toDate())));
    } else {
      duration = moment.duration(
        moment(expirationDate).diff(moment(Created.toDate()))
      );
    }
    const minutes = duration.asMinutes();
    const minuteReward =
      (MultiplierAction === "fixed"
        ? MultiplierDaliyReward - dailyReward
        : MultiplierDaliyReward) /
      24 /
      60;
    const accumilatedReward = minuteReward * minutes;
    // console.log({ accumilatedReward });

    return accumilatedReward;
  };

  useEffect(() => {
    if (tokenList) {
      getRewardPools();
    }
    return;
  }, [tokenList]);

  const getRewardPools = async () => {
    const { VaultAddress } = stakingProjectData;
    const rewardPoolMap: any = {};
    for (const rewardTokenAddress of tokenList) {
      if (
        rewardTokenAddress === "So11111111111111111111111111111111111111112"
      ) {
        const SolBalance = await getVaultSolRewardAvailableInVault();
        if (SolBalance) {
          if (!rewardPoolMap[rewardTokenAddress]) {
            rewardPoolMap[rewardTokenAddress] = SolBalance / LAMPORTS_PER_SOL;
          }
        }
      } else {
        const data = await getRewardTokenBalanceInVault(
          VaultAddress,
          rewardTokenAddress
        );
        if (!rewardPoolMap[rewardTokenAddress]) {
          rewardPoolMap[rewardTokenAddress] = data;
        }
      }
    }
    setRewardPool(rewardPoolMap);
  };

  const showRewardPoolWarning = (rewardTokenAddress: string) => {
    if (rewardPool?.[rewardTokenAddress]) {
      for (const key in balance) {
        const { RewardTokenAddress, amount } = balance[key];
        if (rewardTokenAddress === RewardTokenAddress) {
          return amount > rewardPool[rewardTokenAddress];
        }
      }
    }
    return false;
  };

  const getVaultSolRewardAvailableInVault = async () =>
    new Promise<any>((resolve) => {
      if (publicKey) {
        // stakingProjectData.ProjectId;
        // vaultId;
        // resolve(2);
        customFirebaseRequest("V5User_getSolRewardPoolSize", {
          projectId: stakingProjectData.ProjectId,
        })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(undefined);
          });
      }
    });

  const requestWithdrawel = (
    tokenId: string,
    transaction: Transaction,
    serializedTransaction: SerializedTransaction,
    txid: string
  ) =>
    new Promise<any>((resolve) => {
      if (publicKey) {
        stakingProjectData.ProjectId;
        vaultId;

        customFirebaseRequest("V6_requestHandleWithdrawQueueRequest", {
          projectId: stakingProjectData.ProjectId,
          vaultId,
          walletAddress: publicKey.toString(),
          tokenId,
          trans: transaction.serialize(),
          transactionRequestPayload: serializedTransaction,
          txid,
        })
          .then((res) => {
            console.log(res);
            resolve(true);
          })
          .catch((err) => {
            console.log(err);
            resolve(false);
          });
      }
    });

  const handleWithdrawRequest = async (
    tokenId: string,
    tokenAddress: string
  ) => {
    if (
      vaultId &&
      stakingProjectData &&
      signTransaction &&
      publicKey
    ) {
      // TODO: add ATA request
      const transaction = await createLocalSolTransactionWithRewardATA(
        publicKey.toString(),
        stakingProjectData.VaultAddress,
        0.01,
        tokenAddress
      );
      if (!transaction) {
        return;
      }
      const singedTransaction = await signAndReturnTransaction(transaction);
      if (!singedTransaction) {
        return;
      }
      setLoading(true);

      const serializedTransaction = serializeTransaction(singedTransaction);

      try {
        const txid = await tryConfirmTransactionOfficial(
          singedTransaction,
          ClientRPC
        );
        if (txid) {
          const success = await requestWithdrawel(
            tokenId,
            singedTransaction,
            serializedTransaction,
            txid
          );
          if (success) {
            addToast(
              <p>
                <b>Withdrawal request was successfully sent!</b>
                <br />
                Your Tokens should arrive soon.
              </p>,
              { appearance: "success" }
            );
            // addToast(
            //   <p>
            //     Due to the recent Solana congestions, this may take longer the
            //     usual.
            //   </p>,
            //   { appearance: "info" }
            // );
          } else {
            addToast("Oh ho, something went wrong... contact the team.", {
              appearance: "error",
            });
          }
        } else {
          addToast(
            <p>
              <b>Unable to confirm transaction after 120 seconds...</b>
              <br />
              This is probably due to Solana congestion.
              <br />
              <br />
              Please try again.
            </p>,
            { appearance: "error" }
          );
        }
      } catch {}
      setLoading(false);
    }
  };
  const signAndReturnTransaction = (
    transaction: Transaction,
    attempt = 0,
    maxAttempts = 1
  ) =>
    new Promise<Transaction | undefined>(async (resolve) => {
      if (attempt >= maxAttempts) return resolve(undefined);
      try {
        const signedTransation = await signTransaction!(
          transaction
        );
        resolve(signedTransation);
        return signedTransation;
      } catch (err) {
        console.log(err);
        resolve(await signAndReturnTransaction(transaction, attempt + 1));
      }
    });

  const isBusyWithdrawingTokenId = (tokenId: string) => {
    console.log(
      "withdrawingDocs",
      withdrawingDocs.map((doc: any) => doc.ref.path)
    );

    return (
      withdrawingDocs.find((doc: any) => doc.data().tokenId === tokenId) !==
      undefined
    );
  };

  if (withdrawingDocs === undefined) {
    return <></>;
  }

  const getBalanceUI = (b: number) => {
    if (b < 0.000000001) {
      return 11;
    } else if (b < 0.00000001) {
      return 10;
    } else if (b < 0.0000001) {
      return 9;
    } else if (b < 0.000001) {
      return 8;
    } else if (b < 0.00001) {
      return 7;
    } else if (b < 0.0001) {
      return 6;
    } else if (b < 0.001) {
      return 5;
    } else if (b < 0.01) {
      return 4;
    } else if (b < 0.1) {
      return 3;
    }
    return 2;
  };
  return (
    <div className="vault-balance-main-container">
      {stakedDocs &&
        balance &&
        Object.keys(balance).map((key) => {
          return (
            <div key={balance[key].TokenId} className="vault-balance-container">
              <div className="vault-balance-split-container">
                <div className="token-withdraw-container">
                  <div className="token-container">
                    <div className="token-title">${key}</div>
                    <div className="token-value">
                      <span style={{ fontWeight: 100 }}>+</span>
                      {(+balance[key].amount).toLocaleString("en", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: getBalanceUI(
                          balance[key].dailyReward / 24 / 60
                        ),
                      })}
                    </div>
                  </div>
                  {hideWithdraw === false &&
                    (loading ? (
                      <CircularProgress color="inherit" />
                    ) : (
                      <>
                        {isBusyWithdrawingTokenId(balance[key].TokenId) ? (
                          <Button
                            color="inherit"
                            size="small"
                            disabled={true}
                            style={{ color: "#ffffff66", fontSize: "12px" }}
                          >
                            WITHDRAWING...
                          </Button>
                        ) : (
                          <Button
                            color="inherit"
                            size="small"
                            onClick={(e) =>
                              handleWithdrawRequest(
                                balance[key].TokenId,
                                balance[key].RewardTokenAddress
                              )
                            }
                          >
                            WITHDRAW
                          </Button>
                        )}
                      </>
                    ))}
                </div>
                {showRewardPoolWarning(balance[key].RewardTokenAddress) && (
                  <div className="reward-pool-alert-container">
                    <div className="title">REWARD POOL IS ALMOST EMPTY</div>
                    <p>
                      Your NFTs will not unstake until you receive your earned
                      rewards.
                      <br />
                      Please ask your project manager to refund the reward pool.
                    </p>
                  </div>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export const getRewardTokenBalanceInVault = async (
  vaultAddress: string,
  tokenAddress: string
) => {
  try {
    const tokenData = await anybodiesConnection.getTokenSupply(
      new PublicKey(tokenAddress)
    );

    const response = await axios.default({
      url: `https://weathered-white-sunset.solana-mainnet.quiknode.pro/25f8d47456fd094f2a4bcead278e3683b7a7762f/`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: [
        {
          jsonrpc: "2.0",
          id: 1,
          method: "getTokenAccountsByOwner",
          params: [
            vaultAddress,
            {
              mint: tokenAddress,
            },
            {
              encoding: "jsonParsed",
            },
          ],
        },
      ],
    });
    const availabelRewards =
      +response?.data?.[0]?.result?.value?.[0]?.account?.data?.parsed?.info
        ?.tokenAmount?.amount || 0;
    const decimals = Math.pow(10, tokenData.value.decimals);
    return availabelRewards / decimals;
  } catch {
    return 0;
  }
};
