import { useWallet } from "@solana/wallet-adapter-react";
import { useEffect, useState } from "react";
import { AButton } from "../../../Vault/ReusableConponants/AButton/AButton";
import { useTwitterSocial } from "../../../../services/TwitterSocialService/TwitterSocialService";
import "./TwitterWalletSupport.scss";

export const TwitterWalletSupport = () => {
  return (
    <div className="twitter-wallet-support-main-container">
      This wallet is not linked to your account
      <AButton>Verify Wallet</AButton>
    </div>
  );
};
