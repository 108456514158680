import {
  Connection,
  PublicKey,
  SystemProgram,
  Transaction,
  TransactionInstruction,
} from "@solana/web3.js";
import { Token, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { createOCPLock, createOCPTransfer } from "../../Services/OCP";
import { computeBudgetIx } from "@magiceden-oss/open_creator_protocol";
import { customFirebaseRequest } from "../../Services/FirebaseService/FirebaseService";
// http   s://ssc-dao.genesysgo.net/
export const anybodiesConnection = new Connection(
  "https://weathered-white-sunset.solana-mainnet.quiknode.pro/25f8d47456fd094f2a4bcead278e3683b7a7762f/",
  "confirmed"
);

export type SerializedTransaction = {
  instructionsDTO: {
    data: number[];
    keysDTO: {
      pubkey: string;
      isSigner: boolean;
      isWritable: boolean;
    }[];
    programId: string;
  }[];
  signituresDTO: any[];
  recentBlockhash: string;
  publicKey?: string;
};

type LocalTransactionBuilder = {
  senderPublicAddress: string;
  destPublicAddress: string;
  SPLInstructions: {
    OCP: { OCP: boolean; policy: string };
    senderATA: string;
    authATA: string;
    tokenAddress: string;
  }[];
  amount: number;
};

export const createLocalTransaction = async (
  {
    destPublicAddress,
    senderPublicAddress,
    SPLInstructions,
    amount,
  }: LocalTransactionBuilder,
  pnftMap: any,
  attempt = 0,
  maxAttemps = 5
): Promise<Transaction | undefined> => {
  if (attempt === maxAttemps) {
    return undefined;
  }
  console.log(SPLInstructions);
  
  try {
    const instructions: TransactionInstruction[] = [];
    if (SPLInstructions.find(({ OCP }) => OCP?.OCP)) {
      instructions.push(computeBudgetIx);
    }

    const { data: pnftMap } = await customFirebaseRequest(
      "SOLANA_areTokensPNFT",
      SPLInstructions.map(({ tokenAddress }) => tokenAddress)
    );
console.log("pnftMap",pnftMap);

    for (const {
      senderATA,
      authATA,
      tokenAddress,
      OCP,
    } of SPLInstructions) {
      if (pnftMap[tokenAddress] === 0)
        instructions.push(
          ...(OCP?.OCP
            ? createOCPLock({
                from: new PublicKey(senderPublicAddress),
                fromAta: new PublicKey(senderATA),
                mint: new PublicKey(tokenAddress),
                to: new PublicKey(destPublicAddress),
                policy: OCP.policy,
              })
            : [
                Token.createTransferInstruction(
                  TOKEN_PROGRAM_ID,
                  new PublicKey(senderATA),
                  new PublicKey(authATA),
                  new PublicKey(senderPublicAddress),
                  [],
                  1
                ),
              ])
        );
    }
    instructions.push(
      SystemProgram.transfer({
        fromPubkey: new PublicKey(senderPublicAddress),
        toPubkey: new PublicKey(destPublicAddress),
        lamports: amount,
      })
    );
    const privateConnection = new Connection(
      "https://weathered-white-sunset.solana-mainnet.quiknode.pro/25f8d47456fd094f2a4bcead278e3683b7a7762f/"
    );

    let transaction = new Transaction().add(...instructions);
    transaction.feePayer = new PublicKey(senderPublicAddress);
    transaction.recentBlockhash = (
      await privateConnection.getLatestBlockhash()
    ).blockhash;
    return transaction;
  } catch (err) {
    console.log(err);
    return await createLocalTransaction(
      {
        destPublicAddress,
        senderPublicAddress,
        SPLInstructions,
        amount,
      },
      pnftMap,
      attempt + 1
    );
  }
};

export const getTransaction = (
  serializedTransaction: SerializedTransaction
) => {
  let finalTransaction = new Transaction();

  const instructions: any = serializedTransaction.instructionsDTO.map(
    (item) => ({
      data: new Uint8Array(item.data),
      programId: new PublicKey(item.programId),
      keys: item.keysDTO.map((item) => ({
        ...item,
        pubkey: new PublicKey(item.pubkey),
      })),
    })
  );
  console.log(instructions);

  finalTransaction.add(...instructions);
  finalTransaction.feePayer = new PublicKey(serializedTransaction.publicKey!)!;
  finalTransaction.recentBlockhash = serializedTransaction.recentBlockhash;

  for (
    let index = 0;
    index < serializedTransaction.signituresDTO.length;
    index++
  ) {
    const sign = serializedTransaction.signituresDTO[index];
    // console.log();

    finalTransaction.signatures.push({
      publicKey: new PublicKey(sign.publicKey),
      signature:
        sign.signature.length > 0 ? new Uint8Array(sign.signature) : null,
    } as any);
  }
  // console.log(finalTransaction);
  return finalTransaction;
};

export const serializeTransaction = (
  transaction: any
): SerializedTransaction => {
  const instructionsDTO = [];
  for (let index = 0; index < transaction.instructions.length; index++) {
    const keysDTO = [];
    for (let k = 0; k < transaction.instructions[index].keys.length; k++) {
      keysDTO.push({
        ...transaction.instructions[index].keys[k],
        pubkey: transaction.instructions[index].keys[k].pubkey.toString(),
      });
    }
    instructionsDTO.push({
      data: [...transaction.instructions[index].data],
      keysDTO,
      programId: transaction.instructions[index].programId.toString(),
    });
  }

  const signituresDTO = [];
  for (let index = 0; index < transaction.signatures.length; index++) {
    const sig = transaction.signatures[index];
    signituresDTO.push({
      publicKey: sig.publicKey.toString(),
      signature: sig.signature ? [...sig.signature] : [],
    } as any);
  }

  const serializedData = {
    instructionsDTO,
    signituresDTO,
    recentBlockhash: transaction.recentBlockhash!,
  };
  return serializedData;
};
