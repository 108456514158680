import {
  CMT_PROGRAM,
  computeBudgetIx,
  createInitAccountInstruction,
  createLockInstruction,
  createTransferInstruction,
  findFreezeAuthorityPk,
} from "@magiceden-oss/open_creator_protocol";
import { PublicKey, SYSVAR_INSTRUCTIONS_PUBKEY } from "@solana/web3.js";
import { utils } from "@project-serum/anchor";
import { findMetadataPda } from "@metaplex-foundation/js";
import { ASSOCIATED_TOKEN_PROGRAM_ID } from "@solana/spl-token";
export const MAINNET_POLICY_ALL = new PublicKey(
  "6YYU5avkpFyZN2EJqW6jkttg9BnonJ7rsutJi6rP6y1b"
);

/**
 * Program address
 *
 * @category constants
 * @category generated
 */
export const PROGRAM_ADDRESS = "ocp4vWUzA2z2XMYJ3QhM9vWdyoyoQwAFJhRdVTbvo9E";

/**
 * Program public key
 *
 * @category constants
 * @category generated
 */
export const PROGRAM_ID = new PublicKey(PROGRAM_ADDRESS);

export const createOCPLock = ({
  mint,
  from,
  fromAta,
  to,
  policy,
}: {
  mint: PublicKey;
  from: PublicKey;
  fromAta: PublicKey;
  to: PublicKey;
  policy: string;
}) => {
  const lockIx = createLockInstruction({
    policy: new PublicKey(policy),
    mint: mint,
    metadata: findMetadataPda(mint),
    mintState: findMintStatePk(mint),
    from: from,
    fromAccount: fromAta,
    cmtProgram: CMT_PROGRAM,
    instructions: SYSVAR_INSTRUCTIONS_PUBKEY,
    to,
  });
  return [ lockIx];
};

export const createOCPTransfer = ({
  mint,
  from,
  fromAta,
  to,
  toAta,
}: {
  mint: PublicKey;
  from: PublicKey;
  fromAta: PublicKey;
  to: PublicKey;
  toAta: PublicKey;
}) => {
  const initAccIx = createInitAccountInstruction({
    policy: MAINNET_POLICY_ALL,
    freezeAuthority: findFreezeAuthorityPk(MAINNET_POLICY_ALL),
    mint: mint,
    metadata: findMetadataPda(mint),
    mintState: findMintStatePk(mint),
    from: from,
    fromAccount: fromAta,
    cmtProgram: CMT_PROGRAM,
    instructions: SYSVAR_INSTRUCTIONS_PUBKEY,
    payer: from,
    associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
  });

  const transferIx = createTransferInstruction({
    policy: MAINNET_POLICY_ALL,
    freezeAuthority: findFreezeAuthorityPk(MAINNET_POLICY_ALL),
    mint: mint,
    metadata: findMetadataPda(mint),
    mintState: findMintStatePk(mint),
    from: from,
    fromAccount: fromAta,
    cmtProgram: CMT_PROGRAM,
    instructions: SYSVAR_INSTRUCTIONS_PUBKEY,
    to: to,
    toAccount: toAta,
  });

  return [computeBudgetIx, initAccIx, transferIx];
};

export const findMintStatePk = (mint: PublicKey) => {
  return PublicKey.findProgramAddressSync(
    [utils.bytes.utf8.encode("mint_state"), mint.toBuffer()],
    PROGRAM_ID
  )[0];
};
