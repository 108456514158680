export const openTab = (url: string) => window.open(url, "_blank");

export const getLevelBySession = (levels: any[], session: number) => {
  let level = 1;
  let remainingSessions = session;
  for (let i = 0; i < levels.length; i++) {
    const { stakingSession } = levels[i];
    if (stakingSession >= remainingSessions || levels.length === i + 1) break;
    level += 1;
    remainingSessions -= stakingSession;
  }
  return level;
};

export const LEVEL_COLORS = [
  "#c501e2",
  "#f82d97",
  "#e830ce",
  "#ffa300",
  "#cf0060",
  "#ff00ff",
  "#13a8fe",
  "#ff0571",
  "#ff0534",
  "#903495",
  "#0028ff",
  "#8400ff",
];

export enum MultiplierActionType {
  Addition = "addition",
  Multiplication = "multiplication",
  Fixed = "fixed",
}

export const getMultipliedRewardByType = (
  type: MultiplierActionType,
  reward: number,
  multiplier: number
) => {
  let multipliedReward = 0;
  switch (type) {
    case MultiplierActionType.Fixed:
      multipliedReward = multiplier;
      break;
    default:
      multipliedReward = reward + multiplier;
      break;
  }
  return multipliedReward;
};
