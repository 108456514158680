import { useWallet } from "@solana/wallet-adapter-react";
import { AButton } from "../AButton/AButton";
import "./AUnstakingButton.scss";

import { useToasts } from "react-toast-notifications";
import { useState } from "react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { Connection, Transaction } from "@solana/web3.js";
import { useFirebase } from "../../Services/FirebaseService/FirebaseService";
import { createLocalSolTransaction } from "../../../../utils/Web3Actions/SolTransfer";
import { sendSignedTransaction } from "../../../../services/web3/RetryTransaction";
import { useVault } from "../../Services/VaultService/VaultService";
import * as axios from "axios";

export const AUnstakingButton: React.FC<{
  closeModal: any;
  handleBusy: any;
  endpoint: string;
  stakeDocIds: string[];
}> = ({ closeModal, handleBusy, stakeDocIds, endpoint }) => {
  const { addToast } = useToasts();
  const {
    vaultId,
    stakingProjectData,
    handleGetTokensInWallet,
    vaultConfig: { ClientRPC },
  } = useVault();
  const { firestore, customFirebaseRequest } = useFirebase();
  const [busy, setBusy] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(0.1);
  const { publicKey, signTransaction, signAllTransactions } = useWallet();

  const getMessage = () => {
    switch (currentStatus) {
      case 0.1:
        return "Creating request...";
      case 0:
        return "Confirming your transaction...";
      case 1:
        return "Unstaking your tokens";
      default:
        return "Busy...";
    }
  };

  const handleStakingRequest = async () => {
    if (signTransaction && publicKey && signAllTransactions) {
      setBusy(true);
      handleBusy(true);
      setCurrentStatus(0.1);
      const {
        data: { data },
      } = await axios.default.post(
        "http://localhost:5000/nft-anybodies/us-central1/SUBS_getUnsubscriptionRequest",
        {
          data: {
            accountId: stakingProjectData.ProjectId,
            communityId: vaultId,
            stakedDocIds: stakeDocIds,
            stakedMultiplierDocIds: [],
            walletAddress: publicKey.toString(),
          },
        }
      );
      console.info(data);

      if (!data.success) {
        addToast(data.error.title, {
          appearance: "warning",
          autoDismiss: true,
        });
        setBusy(false);
        handleBusy(false);
        closeModal();
      }
      const { rawTransaction, requestId } = data;
      const transaction = Transaction.from(rawTransaction.data);
      try {
        const signedTransaction = await signTransaction(transaction);
        const buf = signedTransaction.serialize({
          requireAllSignatures: false,
          verifySignatures: false,
        });
        console.log({
          rawTransaction: Array.from(buf),
          requestId,
        });

        const {
          data: { data: processData },
        } = await axios.default.post(
          "http://localhost:5000/nft-anybodies/us-central1/SUBS_processUnsubscriptionRequest",
          {
            data: {
              transactionRequest: {
                rawTransaction: Array.from(buf),
                requestId,
              },
            },
          }
        );
        console.info({ processData });
        setBusy(false);
        handleBusy(false);
        closeModal();
      } catch (err) {
        console.log("User rejected the transaction.");
        setBusy(false);
        handleBusy(false);
        closeModal();
      }
    }
  };

  const handleStakingRequestOld = async () => {
    if (signTransaction && publicKey) {
      setBusy(true);
      handleBusy(true);
      setCurrentStatus(0.1);
      try {
        const solTransaction = await createLocalSolTransaction(
          publicKey.toString(),
          stakingProjectData.VaultAddress,
          0.001
        );
        if (!solTransaction) {
          addToast("Oh ho, something went wrong... contact the team.", {
            appearance: "error",
          });
          return undefined;
        }
        const singedTransaction = await signAndReturnTransaction(
          solTransaction
        );
        if (!singedTransaction) {
          addToast("Oh ho, something went wrong... contact the team.", {
            appearance: "error",
          });
          return undefined;
        }
        if (singedTransaction) {
          setCurrentStatus(1);
          const txid = await tryConfirmTransaction(singedTransaction);
          if (txid) {
            const res = await processUnstakeTransactionRequest(
              singedTransaction,
              publicKey.toString(),
              txid
            );
            addToast(
              <p>
                <b>Your unstake request was successfully sent!</b>
                <br />
                Your NFTs will return to you soon
              </p>,
              { appearance: "success" }
            );
            // addToast(
            //   <p>
            //     Due to the recent Solana congestions, this may take longer than
            //     usual.
            //   </p>,
            //   { appearance: "info" }
            // );
            setBusy(false);
            handleBusy(false);
            closeModal();
            handleGetTokensInWallet();
          } else {
            addToast(
              <p>
                <b>Unable to confirm transaction after 120 seconds...</b>
                <br />
                This is probably due to Solana congestion.
                <br />
                <br />
                Please try again.
              </p>,
              { appearance: "error" }
            );
            setCurrentStatus(404);
            setBusy(false);
            handleBusy(false);
            closeModal();
          }
          return true;
        }
      } catch {
        setBusy(false);
        handleBusy(false);
      }
      return undefined;
    }
    return undefined;
  };

  const tryConfirmTransaction = async (finalTransaction: Transaction) => {
    const connection = new Connection(ClientRPC);
    try {
      const txid = await sendSignedTransaction({
        connection: connection,
        signedTransaction: finalTransaction,
      });
      if (txid) {
        return txid;
      } else {
        return undefined;
      }
    } catch (err) {
      return undefined;
    }
  };

  const processUnstakeTransactionRequest = (
    transaction: Transaction,
    walletAddress: string,
    txid: string
  ) =>
    new Promise<any>((resolve) => {
      customFirebaseRequest(endpoint, {
        stakedDocIds: stakeDocIds,
        projectId: stakingProjectData.ProjectId,
        vaultId,
        trans: transaction.serialize(),
        walletAddress,
        txid,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error: any) => {
          console.log(error);
          resolve(false);
        });
    });

  const signAndReturnTransaction = (
    transaction: Transaction,
    attempt = 0,
    maxAttempts = 1
  ) =>
    new Promise<Transaction | undefined>(async (resolve) => {
      if (attempt >= maxAttempts) {
        setBusy(false);
        handleBusy(false);
        return resolve(undefined);
      }
      try {
        const signedTransation = await signTransaction!(transaction);
        resolve(signedTransation);
        return signedTransation;
      } catch (err) {
        console.log(err);
        resolve(await signAndReturnTransaction(transaction, attempt + 1));
      }
    });

  if (busy === true) {
    return (
      <>
        {stakeDocIds.length > 0 && (
          <div className="minting-button-main-container">
            <div className="minting-button-loader"></div>
            <div className="message">{getMessage()}</div>
            <div className="may-take">
              May take a moment, trust the process!
            </div>
          </div>
        )}
      </>
    );
  } else {
    return (
      <>
        {stakeDocIds.length > 0 && (
          <div className="minting-button-main-container">
            {publicKey && (
              <AButton
                disabled={stakeDocIds.length === 0}
                style={{
                  width: "fit-content",
                  margin: "auto",
                }}
                onClick={handleStakingRequest}
              >
                UNSTAKE
              </AButton>
            )}
            {!publicKey && <WalletMultiButton className="wallet-btn" />}
          </div>
        )}
      </>
    );
  }
};
