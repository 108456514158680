export const Terms = () => {
  return (
    <div>
      <div style={{ padding: "20px", color: "white"  }}>
        <div className="c7">
          <h1 className="c6" id="h.p7i0g08nrf76">
            <span className="c4">Terms and conditions</span>
          </h1>
          <p className="c2">
            <span className="c0">
              These terms and conditions (&quot;Terms&quot;,
              &quot;Agreement&quot;) are an agreement between Diamond Vaults
              (&quot;Diamond Vaults&quot;, &quot;us&quot;, &quot;we&quot; or
              &quot;our&quot;) and you (&quot;User&quot;, &quot;you&quot; or
              &quot;your&quot;). This Agreement sets forth the general terms and
              conditions of your use of the diamondvaults.io Web Application and
              any of its products or services (collectively, &quot;Mobile
              Application&quot; or &quot;Services&quot;).
            </span>
          </p>
          <h2 className="c1" id="h.e3wzasuqxbds">
            <span className="c3">Accounts and membership</span>
          </h2>
          <p className="c2">
            <span className="c0">
              You must be at least 18 years of age to use this Web
              Application. By using this Mobile Application and by agreeing to
              this Agreement you warrant and represent that you are at least 13
              years of age. If you create an account on the Mobile Application,
              you are responsible for maintaining the security of your account
              and you are fully responsible for all activities that occur under
              the account and any other actions taken in connection with it. We
              may, but have no obligation to, monitor and review new accounts
              before you may sign in and use our Services. Providing false
              contact information of any kind may result in the termination of
              your account. You must immediately notify us of any unauthorized
              uses of your account or any other breaches of security. We will
              not be liable for any acts or omissions by you, including any
              damages of any kind incurred as a result of such acts or
              omissions. We may suspend, disable, or delete your account (or any
              part thereof) if we determine that you have violated any provision
              of this Agreement or that your conduct or content would tend to
              damage our reputation and goodwill. If we delete your account for
              the foregoing reasons, you may not re-register for our Services.
              We may block your email address and Internet protocol address to
              prevent further registration.
            </span>
          </p>
          <h2 className="c1" id="h.snz59mill5jb">
            <span className="c3">User content</span>
          </h2>
          <p className="c2">
            <span className="c0">
              We do not own any data, information or material
              (&quot;Content&quot;) that you submit on the Mobile Application in
              the course of using the Service. You shall have sole
              responsibility for the accuracy, quality, integrity, legality,
              reliability, appropriateness, and intellectual property ownership
              or right to use of all submitted Content. We may monitor and
              review Content on the Mobile Application submitted or created
              using our Services by you. Unless specifically permitted by you,
              your use of the Mobile Application does not grant us the license
              to use, reproduce, adapt, modify, publish or distribute the
              Content created by you or stored in your user account for
              commercial, marketing or any similar purpose. But you grant us
              permission to access, copy, distribute, store, transmit, reformat,
              display and perform the Content of your user account solely as
              required for the purpose of providing the Services to you. Without
              limiting any of those representations or warranties, we have the
              right, though not the obligation, to, in our own sole discretion,
              refuse or remove any Content that, in our reasonable opinion,
              violates any of our policies or is in any way harmful or
              objectionable.
            </span>
          </p>
          <h2 className="c1" id="h.os3hk0pvdjom">
            <span className="c3">Billing and payments</span>
          </h2>
          <p className="c2">
            <span className="c0">
              You shall pay all fees or charges to your account in accordance
              with the fees, charges, and billing terms in effect at the time a
              fee or charge is due and payable. Where Services are offered on a
              free trial basis, payment may be required after the free trial
              period ends, and not when you enter your billing details (which
              may be required prior to the commencement of the free trial
              period). If auto-renewal is enabled for the Services you have
              subscribed for, you will be charged automatically in accordance
              with the term you selected. Sensitive and private data exchange
              between the Mobile Application and its Users happens over a SSL
              secured communication channel and is encrypted and protected with
              digital signatures. If, in our judgment, your purchase constitutes
              a high-risk transaction, we will require you to provide us with a
              copy of your valid government-issued photo identification, and
              possibly a copy of a recent bank statement for the credit or debit
              card used for the purchase. We reserve the right to change
              products and product pricing at any time. We also reserve the
              right to refuse any order you place with us. We may, in our sole
              discretion, limit or cancel quantities purchased per person, per
              household or per order. These restrictions may include orders
              placed by or under the same customer account, the same credit
              card, and/or orders that use the same billing and/or shipping
              address. In the event that we make a change to or cancel an order,
              we may attempt to notify you by contacting the e-mail and/or
              billing address/phone number provided at the time the order was
              made.
            </span>
          </p>
          <h2 className="c1" id="h.ge84kkb1hbsa">
            <span className="c3">Accuracy of information</span>
          </h2>
          <p className="c2">
            <span className="c0">
              Occasionally there may be information on the Mobile Application
              that contains typographical errors, inaccuracies or omissions that
              may relate to product descriptions, availability, promotions and
              offers. We reserve the right to correct any errors, inaccuracies
              or omissions, and to change or update information or cancel orders
              if any information on the Mobile Application or on any related
              Service is inaccurate at any time without prior notice (including
              after you have submitted your order). We undertake no obligation
              to update, amend or clarify information on the Mobile Application
              including, without limitation, pricing information, except as
              required by law. No specified update or refresh date applied on
              the Mobile Application should be taken to indicate that all
              information on the mobile application or on any related Service
              has been modified or updated.
            </span>
          </p>
          <h2 className="c1" id="h.l22hh0n2xvys">
            <span className="c3">Third-party services</span>
          </h2>
          <p className="c2">
            <span className="c0">
              If you decide to enable, access or use third-party services, be
              advised that your access and use of such other services are
              governed solely by the terms and conditions of such other
              services, and we do not endorse, are not responsible or liable
              for, and make no representations as to any aspect of such other
              services, including, without limitation, their content or the
              manner in which they handle data (including your data) or any
              interaction between you and the provider of such other services.
              You irrevocably waive any claim against Diamond Vaults with respect to such
              other services. Diamond Vaults is not liable for any damage or loss caused
              or alleged to be caused by or in connection with your enablement,
              access or use of any such other services, or your reliance on the
              privacy practices, data security processes or other policies of
              such other services. You may be required to register for or log
              into such other services on their respective Mobile Applications.
              By enabling any other services, you are expressly permitting Diamond Vaults
              to disclose your data as necessary to facilitate the use or
              enablement of such other service.
            </span>
          </p>
          <h2 className="c1" id="h.6zau6xs5imut">
            <span className="c3">Backups</span>
          </h2>
          <p className="c2">
            <span className="c0">
              We perform regular backups of the Mobile Application and Content,
              however, these backups are for our own administrative purposes
              only and are in no way guaranteed. You are responsible for
              maintaining your own backups of your data. We do not provide any
              sort of compensation for lost or incomplete data in the event that
              backups do not function properly. We will do our best to ensure
              complete and accurate backups, but assume no responsibility for
              this duty.
            </span>
          </p>
          <h2 className="c1" id="h.frl0pff5sdkr">
            <span className="c3">Advertisements</span>
          </h2>
          <p className="c2">
            <span className="c0">
              During use of the Mobile Application, you may enter into
              correspondence with or participate in promotions of advertisers or
              sponsors showing their goods or services through the Mobile
              Application. Any such activity, and any terms, conditions,
              warranties or representations associated with such activity, is
              solely between you and the applicable third-party. We shall have
              no liability, obligation or responsibility for any such
              correspondence, purchase or promotion between you and any such
              third-party.
            </span>
          </p>
          <h2 className="c1" id="h.zbuc3cf6pfgw">
            <span className="c3">
              Links to other websites or mobile applications{" "}
            </span>
          </h2>
          <p className="c2">
            <span className="c0">
              Although this Mobile Application may link to other Websites or
              Mobile Applications, we are not, directly or indirectly, implying
              any approval, association, sponsorship, endorsement, or
              affiliation with any linked Website Mobile Application, unless
              specifically stated herein. Some of the links on the Mobile
              Application may be &quot;affiliate links&quot;. This means if you
              click on the link and purchase an item, Diamond Vaults will receive an
              affiliate commission. We are not responsible for examining or
              evaluating, and we do not warrant the offerings of, any businesses
              or individuals or the content of their Websites Mobile
              Applications. We do not assume any responsibility or liability for
              the actions, products, services, and content of any other
              third-parties. You should carefully review the legal statements
              and other conditions of use of any Mobile Application which you
              access through a link from this Mobile Application. Your linking
              to any other off-site Websites or Mobile Applications is at your
              own risk.
            </span>
          </p>
          <h2 className="c1" id="h.ndjvq9n2oyz7">
            <span className="c3">Prohibited uses</span>
          </h2>
          <p className="c2">
            <span className="c0">
              In addition to other terms as set forth in the Agreement, you are
              prohibited from using the Mobile Application or its Content: (a)
              for any unlawful purpose; (b) to solicit others to perform or
              participate in any unlawful acts; (c) to violate any
              international, federal, provincial or state regulations, rules,
              laws, or local ordinances; (d) to infringe upon or violate our
              intellectual property rights or the intellectual property rights
              of others; (e) to harass, abuse, insult, harm, defame, slander,
              disparage, intimidate, or discriminate based on gender, sexual
              orientation, religion, ethnicity, race, age, national origin, or
              disability; (f) to submit false or misleading information; (g) to
              upload or transmit viruses or any other type of malicious code
              that will or may be used in any way that will affect the
              functionality or operation of the Service or of any related Mobile
              Application, other Mobile Applications, or the Internet; (h) to
              collect or track the personal information of others; (i) to spam,
              phish, pharm, pretext, spider, crawl, or scrape; (j) for any
              obscene or immoral purpose; or (k) to interfere with or circumvent
              the security features of the Service or any related Mobile
              Application, other Mobile Applications, or the Internet. We
              reserve the right to terminate your use of the Service or any
              related Mobile Application for violating any of the prohibited
              uses.
            </span>
          </p>
          <h2 className="c1" id="h.t8xg92jl0lu0">
            <span className="c3">Intellectual property rights</span>
          </h2>
          <p className="c2">
            <span className="c0">
              This Agreement does not transfer to you any intellectual property
              owned by Diamond Vaults or third-parties, and all rights, titles, and
              interests in and to such property will remain (as between the
              parties) solely with Diamond Vaults All trademarks, service marks, graphics
              and logos used in connection with our Mobile Application or
              Services, are trademarks or registered trademarks of Diamond Vaults or
              Diamond Vaults licensors. Other trademarks, service marks, graphics and
              logos used in connection with our Mobile Application or Services
              may be the trademarks of other third-parties. Your use of our
              Mobile Application and Services grants you no right or license to
              reproduce or otherwise use any Diamond Vaults or third-party trademarks.
            </span>
          </p>
          <h2 className="c1" id="h.5ufv3j4frlqj">
            <span className="c3">Disclaimer of warranty</span>
          </h2>
          <p className="c2">
            <span className="c0">
              You agree that your use of our Mobile Application or Services is
              solely at your own risk. You agree that such Service is provided
              on an &quot;as is&quot; and &quot;as available&quot; basis. We
              expressly disclaim all warranties of any kind, whether express or
              implied, including but not limited to the implied warranties of
              merchantability, fitness for a particular purpose and
              non-infringement. We make no warranty that the Services will meet
              your requirements, or that the Service will be uninterrupted,
              timely, secure, or error-free; nor do we make any warranty as to
              the results that may be obtained from the use of the Service or as
              to the accuracy or reliability of any information obtained through
              the Service or that defects in the Service will be corrected. You
              understand and agree that any material and/or data downloaded or
              otherwise obtained through the use of Service is done at your own
              discretion and risk and that you will be solely responsible for
              any damage to your computer system or loss of data that results
              from the download of such material and/or data. We make no
              warranty regarding any goods or services purchased or obtained
              through the Service or any transactions entered into through the
              Service. No advice or information, whether oral or written,
              obtained by you from us or through the Service shall create any
              warranty not expressly made herein.
            </span>
          </p>
          <h2 className="c1" id="h.jzen7j5srykz">
            <span className="c3">Limitation of liability</span>
          </h2>
          <p className="c2">
            <span className="c0">
              To the fullest extent permitted by applicable law, in no event
              will Diamond Vaults, its affiliates, officers, directors, employees,
              agents, suppliers or licensors be liable to any person for (a):
              any indirect, incidental, special, punitive, cover or
              consequential damages (including, without limitation, damages for
              lost profits, revenue, sales, goodwill, use of content, impact on
              business, business interruption, loss of anticipated savings, loss
              of business opportunity) however caused, under any theory of
              liability, including, without limitation, contract, tort,
              warranty, breach of statutory duty, negligence or otherwise, even
              if Diamond Vaults has been advised as to the possibility of such damages or
              could have foreseen such damages. To the maximum extent permitted
              by applicable law, the aggregate liability of Diamond Vaults and its
              affiliates, officers, employees, agents, suppliers and licensors,
              relating to the services will be limited to an amount greater of
              one dollar or any amounts actually paid in cash by you to Diamond Vaults
              for the prior one month period prior to the first event or
              occurrence giving rise to such liability. The limitations and
              exclusions also apply if this remedy does not fully compensate you
              for any losses or fails of its essential purpose.
            </span>
          </p>
          <h2 className="c1" id="h.1hc99lyqscku">
            <span className="c3">Indemnification</span>
          </h2>
          <p className="c2">
            <span className="c0">
              You agree to indemnify and hold Diamond Vaults and its affiliates,
              directors, officers, employees, and agents harmless from and
              against any liabilities, losses, damages or costs, including
              reasonable attorneys&#39; fees, incurred in connection with or
              arising from any third-party allegations, claims, actions,
              disputes, or demands asserted against any of them as a result of
              or relating to your Content, your use of the Mobile Application or
              Services or any willful misconduct on your part.
            </span>
          </p>
          <h2 className="c1" id="h.g7umzikcps2s">
            <span className="c3">Severability</span>
          </h2>
          <p className="c2">
            <span className="c0">
              All rights and restrictions contained in this Agreement may be
              exercised and shall be applicable and binding only to the extent
              that they do not violate any applicable laws and are intended to
              be limited to the extent necessary so that they will not render
              this Agreement illegal, invalid or unenforceable. If any provision
              or portion of any provision of this Agreement shall be held to be
              illegal, invalid or unenforceable by a court of competent
              jurisdiction, it is the intention of the parties that the
              remaining provisions or portions thereof shall constitute their
              agreement with respect to the subject matter hereof, and all such
              remaining provisions or portions thereof shall remain in full
              force and effect.
            </span>
          </p>
          <h2 className="c1" id="h.35mg8a8z8cse">
            <span className="c3">Dispute resolution</span>
          </h2>
          <p className="c2">
            <span className="c0">
              The formation, interpretation, and performance of this Agreement
              and any disputes arising out of it shall be governed by the
              substantive and procedural laws of Johannesburg, South Africa
              without regard to its rules on conflicts or choice of law and, to
              the extent applicable, the laws of Israel. The exclusive
              jurisdiction and venue for actions related to the subject matter
              hereof shall be the state and federal courts located in
              Johannesburg, South Africa, and you hereby submit to the personal
              jurisdiction of such courts. You hereby waive any right to a jury
              trial in any proceeding arising out of or related to this
              Agreement. The United Nations Convention on Contracts for the
              International Sale of Goods does not apply to this Agreement.
            </span>
          </p>
          <h2 className="c1" id="h.nubm3rydzcdj">
            <span className="c3">Changes and amendments</span>
          </h2>
          <p className="c2">
            <span className="c0">
              We reserve the right to modify this Agreement or its policies
              relating to the Mobile Application or Services at any time,
              effective upon posting of an updated version of this Agreement on
              the Mobile Application. When we do, we will revise the updated
              date at the bottom of this page. Continued use of the Mobile
              Application after any such changes shall constitute your consent
              to such changes.
            </span>
          </p>
          <h2 className="c1" id="h.7zyji7yi1eff">
            <span className="c3">Acceptance of these terms</span>
          </h2>
          <p className="c2">
            <span className="c0">
              You acknowledge that you have read this Agreement and agree to all
              its terms and conditions. By using the Mobile Application or its
              Services you agree to be bound by this Agreement. If you do not
              agree to abide by the terms of this Agreement, you are not
              authorized to use or access the Mobile Application and its
              Services.
            </span>
          </p>
          <h2 className="c1" id="h.g3xllrlohdsx">
            <span className="c3">Additional noteworthy terms</span>
          </h2>
          <p className="c2">
            <span className="c8">
              Diamond Vaults&rsquo;s inclusion of all trademarks of any sport-related
              entity is in no way used for the purpose of monetization. Diamond Vaults
              acknowledges the importance of the trademarks and the role they
              play in the brands that have been created for each of the
              respective entities. Diamond Vaults is unequivocally in no way attempting
              to profit from the use of these trademarks.
            </span>
          </p>
          <h2 className="c1" id="h.ktamml4zi242">
            <span className="c3">Contacting us</span>
          </h2>
          <p className="c2">
            <span className="c0">
              If you would like to contact us to understand more about this
              Agreement or wish to contact us concerning any matter relating to
              it, you may send an email to support@diamondvaults.io
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              This document was last updated on May 10, 2022
            </span>
          </p>
          <p className="c9">
            <span className="c5"></span>
          </p>
        </div>
      </div>
    </div>
  );
};
