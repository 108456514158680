import { openTab } from "../../utils";
import "./Footer.scss";
import iconTwitter from "../../assets/images/social-icon/icon-twitter.svg";
import iconDiscord from "../../assets/images/social-icon/icon-discord.svg";

const icons = [
  {
    url: "https://bit.ly/3BGdRTG",
    asset: iconTwitter,
  },
  {
    url: "https://discord.gg/nKsKPGkwby",
    asset: iconDiscord,
  },
];

export const Footer = () => {
  return (
    <div className="footer-main-container">
      <div className="social-items-container">
        {icons.map(({ url, asset }) => (
          <div onClick={() => openTab(url)} className="social-item" key={url}>
            <img className="social-img" src={asset} alt="Social" />
          </div>
        ))}
      </div>
      <div className="copyrights-text">
        Copyright © {new Date().getFullYear()} Diamond Vaults inc. All right
        reserved.
      </div>
    </div>
  );
};
