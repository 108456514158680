import { useEffect, useState } from "react";
import { useTwitterSocial } from "../../../../services/TwitterSocialService/TwitterSocialService";
import "./TwitterProfileSection.scss";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useWallet } from "@solana/wallet-adapter-react";
import { TwitterWalletSupport } from "../TwitterWalletSupport/TwitterWalletSupport";
import { TwitterTweetEmbed } from "react-twitter-embed";
export const TwitterProfileSection = () => {
  const { twitterUserSnap, isWalletSupported } = useTwitterSocial();
  const [twitterUserData, setTwitterUserData] = useState<any>();
  const { publicKey } = useWallet();
  useEffect(() => {
    if (twitterUserSnap) {
      setTwitterUserData(twitterUserSnap.data());
    }
  }, [twitterUserSnap]);

  if (twitterUserData)
    return (
      <div className="twitter-profile-main-container">
        <h3>{twitterUserData.displayName}</h3>
        <TwitterWalletWrapper />
        {publicKey && !isWalletSupported && <TwitterWalletSupport />}
        <div className="tweets-container">
          <TwitterTweetEmbed
            //   backgroundColor="#ff0000"
            onLoad={function noRefCheck() {}}
            options={{
              hideCard: false,
              hideThread: false,
              maxWidth: 300,
              width: 300,
            }}
            tweetId="1529783039131279365"
          />
          <TwitterTweetEmbed
            //   backgroundColor="#ff0000"
            onLoad={function noRefCheck() {}}
            options={{
              hideCard: false,
              hideThread: false,
              maxWidth: 300,
              width: 300,
            }}
            tweetId="1529449144921866240"
          />
        </div>
      </div>
    );
  return <></>;
};

export const TwitterWalletWrapper = () => {
  const [start, setStart] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setStart(true);
    }, 20);
  }, []);
  return (
    <div className="twitter-wallet-wrapper">
      {start && (
        <>
          <WalletMultiButton className="wallet-btn" />
        </>
      )}
    </div>
  );
};
