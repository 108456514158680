/* eslint-disable react-hooks/exhaustive-deps */
import { useWallet } from "@solana/wallet-adapter-react";
import { createContext, useContext, useEffect, useState } from "react";
import { useFirebase } from "../../pages/Vault/Services/FirebaseService/FirebaseService";

export type TwitterSocialData = {
  twitterUserSnap?: any;
  isWalletSupported?: boolean;
  twitterUserData?: any;
};

export const TwitterSocialContext = createContext<TwitterSocialData>({});

export const useTwitterSocial = () => {
  return useContext(TwitterSocialContext);
};
export const TwitterSocialService = ({ children }: any) => {
  const { user, firestore, customFirebaseRequestEurope } = useFirebase();
  const [twitterUserSnap, setTwitterUserSnap] = useState<any>();
  const [twitterUserData, setTwitterUserData] = useState<any>();
  const [isWalletSupported, setIsWalletSupported] = useState<any>();
  const { publicKey } = useWallet();

  useEffect(() => {
    if (user) {
      return firestore
        .doc(`/ATwitterUser/${user!.uid}`)
        .onSnapshot(setTwitterUserSnap);
    } else {
      //   setTwitterUserSnap(null);
      //   setTwitterUserData(null);
      //   isWalletSupported(null);
    }
  }, [user]);

  useEffect(() => {
    if (twitterUserSnap) {
      setTwitterUserData(twitterUserSnap.data());
    }
  }, [twitterUserSnap]);

  useEffect(() => {
    if (twitterUserData && publicKey) {
      console.log({ twitterUserData, publicKey: publicKey.toString() });
      setIsWalletSupported(
        twitterUserData.supportedWallets.includes(publicKey!.toString())
      );
    }
  }, [twitterUserData, publicKey]);

  useEffect(() => {
    if (isWalletSupported !== undefined) {
      if (isWalletSupported) {
        getAvailableCampaigns();
      } else {
      }
    }
  }, [isWalletSupported]);

  const getAvailableCampaigns = async () => {
    customFirebaseRequestEurope("SOCIAL_V1_GetAvailableCampaigns", {
      idToken: await user?.getIdToken(),
      payload: {
        walletAddress: publicKey!.toString(),
      },
    }).then(({ data }) => {
      console.log(data);
    });
  };

  const value = { twitterUserSnap, twitterUserData, isWalletSupported };

  return (
    <TwitterSocialContext.Provider value={value}>
      {children}
    </TwitterSocialContext.Provider>
  );
};
