import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getLevelBySession } from "../../../../../utils/index";
import ContractInformationDialogLevels from "./ContractInformationDialogLevels";
import { SimulateDialogProps } from "./types";

const ContractInformationDialog = ({ contract, hide }: SimulateDialogProps) => {
  const [level, setLevel] = useState(1);

  useEffect(() => {
    if (!contract?.stakingSession) {
      setLevel(1);
      return;
    }

    setLevel(
      getLevelBySession(contract?.maturityLevels, contract.stakingSession)
    );
  }, [contract?.maturityLevels, contract?.stakingSession]);

  return (
    <Dialog
      open={!!contract}
      onClose={hide}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {contract && (
        <>
          <DialogTitle id="alert-dialog-title">
            {contract?.PlanTitle}
          </DialogTitle>
          <DialogContent>
            <Box key={contract?.AStakingProgramId}>
              <Typography>{contract?.PlanDescription}</Typography>
              {contract?.maturityActive ? (
                <ContractInformationDialogLevels
                  contract={contract}
                  level={level}
                />
              ) : (
                <Box sx={{ marginTop: 2 }}>
                  <Typography>
                    <strong>Daily reward: </strong>
                    {contract?.dailyReward} tokens per day
                  </Typography>
                  {contract?.multiplierInfo && (
                    <Typography>
                      <strong>Daily reward with multiplier: </strong>
                      {contract?.baseDailyReward +
                        (contract.multiplierInfo?.MultiplierDaliyReward ||
                          0)}{" "}
                      tokens per day
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={hide}
              autoFocus
              variant="outlined"
              color="inherit"
              fullWidth
            >
              Close
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default ContractInformationDialog;
