import { AButton } from "../../ReusableConponants/AButton/AButton";
import { useMultiplierStakingModal } from "./MultiplierStakingModalService/MultiplierStakingModalService";
import "./MultiplierSection.scss";
import { MultiplierItem } from "./MultiplierItem/MultiplierItem";
import { MultiplierInfoModalProvider } from "./MultiplierInfoModalService/MultiplierInfoModalService";
import { useVault } from "../../Services/VaultService/VaultService";
import { useUnstakeMultiplierModal } from "./UnstakeMultiplierModalService/UnstakeModalService";

export const MultiplierSection = () => {
  const { onStakeSelected } = useMultiplierStakingModal();
  const { onUnstackSelected } = useUnstakeMultiplierModal();
  const { stakingProjectData, multiplierStakedDocs } = useVault();
  if (stakingProjectData?.ShowMultiplier === true)
    return (
      <MultiplierInfoModalProvider>
        <div className="multiplier-main-container">
          <div className="multiplier-title">MULTIPLIERS</div>
          <div className="multiplier-actions-container">
            <AButton onClick={onStakeSelected} size="small">
              STAKE MULTIPLIER
            </AButton>
            <AButton onClick={onUnstackSelected} skin="light" size="small">
              UNSTAKE MULTIPLIER
            </AButton>
          </div>
          <div className="multiplier-items-container">
            {multiplierStakedDocs?.map((doc) => {
              return (
                <MultiplierItem
                  key={`${doc.id}-${doc.data().expirationDate.toDate().getTime()}`}
                  itemDoc={doc}
                  itemData={doc.data()}
                />
              );
            })}
          </div>
        </div>
      </MultiplierInfoModalProvider>
    );

  return <></>;
};
