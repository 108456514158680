import { Footer } from "../../components/Footer/Footer";
import Navbar from "../../components/navbar/navbar";
import "./LandingPage.scss";
import { Features } from "./Sections/Features/Features";
import { CTA } from "./Sections/Hero/CTA/CTA";
import { Hero } from "./Sections/Hero/Hero";
import { Overview } from "./Sections/Overview/Overview";
import { Stats } from "./Sections/Stats/Stats";

export const LandingPage = () => {
  return (
    <div className="landing-main-container">
      <Navbar />
      <Hero />
      <CTA />
      <Overview />
      <Features />
      <Stats />
      <Footer/>
    </div>
  );
};
