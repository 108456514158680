import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { getMultipliedRewardByType } from "../../../../../utils/index";
import { ContractInformationDialogLevelsProps, Level } from "./types";

const ContractInformationDialogLevels = ({
  level,
  contract,
}: ContractInformationDialogLevelsProps) => {
  let currentDay = 0;
  let currentReward = contract.baseDailyReward;

  return (
    <TableContainer sx={{ marginTop: 2 }}>
      <Table aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ paddingLeft: 0, fontWeight: "bold" }}>
              Stage
              <Typography
                variant="overline"
                color="gray"
                fontSize={10}
                component="div"
              >
                Duration
              </Typography>
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: "bold" }}>
              Daily reward
              <Typography
                variant="overline"
                color="gray"
                fontSize={10}
                component="div"
              >
                in ${contract.TokenData.TokenName}
              </Typography>
            </TableCell>
            {contract?.multiplierInfo && (
              <TableCell
                align="center"
                sx={{ paddingRight: 0, fontWeight: "bold" }}
              >
                With multiplier
                <Typography
                  variant="overline"
                  color="gray"
                  fontSize={10}
                  component="div"
                >
                  in ${contract.TokenData.TokenName}
                </Typography>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {contract?.maturityLevels?.map(
            ({ stakingSession, addedRewards }: Level, index: number) => {
              const reward = currentReward + addedRewards;

              const multipliedReward = getMultipliedRewardByType(
                contract?.multiplierInfo?.MultiplierAction,
                reward,
                contract?.multiplierInfo?.MultiplierDaliyReward
              );

              return (
                <TableRow key={index}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      paddingLeft: 0,
                      fontWeight: "bold",
                      borderRightWidth: 1,
                    }}
                  >
                    Level {index + 1}
                    <Typography
                      variant="overline"
                      color="gray"
                      fontSize={10}
                      component="div"
                      fontWeight="bold"
                    >
                      days {index === 0 ? currentDay : currentDay + 1}
                      {contract?.maturityLevels.length === index + 1
                        ? "+"
                        : ` - ${(currentDay +=
                            stakingSession * contract.StakingDuration)}`}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      ...(level === index + 1 &&
                        !contract?.multiplierInfo && {
                          backgroundColor: "#ffdd02",
                          color: "#121212",
                        }),
                    }}
                  >
                    {reward}
                  </TableCell>
                  {contract?.multiplierInfo && (
                    <TableCell
                      align="center"
                      sx={{
                        paddingRight: 0,
                        ...(level === index + 1 &&
                          contract?.multiplierInfo && {
                            backgroundColor: "#ffdd02",
                            color: "#121212",
                          }),
                      }}
                    >
                      {multipliedReward}
                    </TableCell>
                  )}
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ContractInformationDialogLevels;
