import React, { useEffect, useState } from "react";
import './VaultStatsSection.scss'
import { useWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import moment from "moment";
import * as axios from 'axios';
import { useFirebase } from "../../Services/FirebaseService/FirebaseService";
import { useVault } from "../../Services/VaultService/VaultService";

export const VaultStatsSection: React.FC<any> = () => {
    const { vaultId, stakedItems, selectedVaultData, stakedDocs, stakingProjectData } = useVault();
    const { firestore } = useFirebase();
    const [vaultData, setVaultData] = useState<any>();
    const [walletData, setwalletData] = useState<any>();
    const [stakeHistory, setStakeHistory] = useState<any>();
    const [usd2SOL, setUSD2SOL] = useState<any>();
    const { publicKey } = useWallet();

    useEffect(() => {
        (async () => {
            setUSD2SOL(await getSOL2USDT())
        })();
    }, []);

    useEffect(() => {
        let unsubscribe;
        if (vaultId && stakingProjectData) {
            unsubscribe = firestore.doc(`/AVaultProject/${stakingProjectData.ProjectId}/AVault/${vaultId}/Monitor/VaultStats`).onSnapshot(doc => {
                if (doc.exists) {
                    setVaultData(doc.data())
                }
            })
        }
        return unsubscribe
    }, [vaultId, stakingProjectData]);

    useEffect(() => {
        let unsubscribe;
        if (vaultId  && publicKey) {
            getStakeHistory(vaultId, publicKey);
            unsubscribe = firestore.doc(`/APublicWallet/${publicKey.toString()}`).onSnapshot(doc => {
                if (doc.exists) {
                    setwalletData(doc.data())
                } else {
                    setwalletData({
                        withdrawnLoco: 0
                    })
                }
            })
        }
        return unsubscribe
    }, [vaultId, publicKey]);

    const getStakeHistory = async (vaultId: string, publicKey: PublicKey) => {
        const res = await firestore.collection(`/Vault/${vaultId}/StakeHistory`)
            .where('walletPublicKey', '==', publicKey.toString())
            .get()
        setStakeHistory(res.docs)
    }

    const getLocoBalance = () => {
        if (stakedDocs) {
            let totalEarnings = 0
            // console.log(selectedVaultData);

            const { StakeDurationDays, LocoPerDay } = selectedVaultData;

            const stakeDocs = [
                ...stakedDocs!,
                ...stakeHistory
            ]
            for (let index = 0; index < stakeDocs.length; index++) {
                const stakedItem = stakeDocs[index];
                // When was it staked
                const given = moment(stakedItem.data().created.toDate());
                // now
                const current = moment();
                // how many days since the stake
                let days = Math.floor(moment.duration(current.diff(given)).asDays());

                /**
                 *  If is has been staked for more days then the max (40 days for example)
                 *  -> then: set to max (30)
                 *  -> else: keep as is (13 days for example)
                 */
                days = days > StakeDurationDays ? StakeDurationDays : days;

                // add to the total earning the number of days mutiplied by the locos per day
                totalEarnings += days * LocoPerDay;
            }
            return totalEarnings - walletData.withdrawnLoco;
        }
        // stakedDocs
        // const duration: number = vualt.data().StakeDurationDays // 30 days
        // const locoPerDay: number = vualt.data().LocoPerDay // 10
        return 0
    }

    return <>
        {
            vaultId &&
            <div className="vault-stats-section-container">
                <div className="vault-stats-item-container">
                    {vaultData ? (<>
                        <div className="value">
                            {vaultData.TotalStaked}
                        </div>
                        <div className="title">STAKED IN VAULT</div>
                    </>) : (<>
                        <div className="box">
                            <div className="coin"></div>
                        </div>
                    </>)}

                </div>
                <div className="vault-stats-item-container">
                    {vaultData && usd2SOL ? (<>
                        <div className="value">${Math.floor((vaultData.TotalStaked * vaultData.FloorPrice * usd2SOL)).toLocaleString()}</div>
                        <div className="title">TVL $USD</div>
                    </>) : (<>
                        <div className="box">
                            <div className="coin"></div>
                        </div>
                    </>)}
                </div>
                {publicKey && <div className="vault-stats-item-container">
                    {stakedItems ? (<>
                        <div className="value">{stakedItems?.length}</div>
                        <div className="title">YOURS STAKED</div>
                    </>) : (<>
                        <div className="box">
                            <div className="coin"></div>
                        </div>
                    </>)}

                </div>}
                {/* {publicKey && vaultId && vaultId === ANYBODIES_VAULT_ID && <div className="vault-stats-item-container">
                    {(stakeHistory && selectedVaultData && walletData && stakedItems) ? (<>
                        <div className="value">{getLocoBalance()}</div>
                        <div className="title">BALANCE</div>
                    </>) : (<>
                        <div className="box">
                            <div className="coin"></div>
                        </div>
                    </>)}
                </div>} */}
            </div>}
    </>
}

export const getSOL2USDT = () => new Promise<number | undefined>((resolve) => {
    var config: any = {
        method: 'get',
        url: 'https://api.binance.com/api/v3/ticker/price?symbol=SOLUSDT',
    };

    axios.default(config)
        .then(function (response) {
            // console.log(JSON.stringify(response.data));
            resolve(+response.data.price)
        })
        .catch(function (error) {
            resolve(undefined)
            // console.log(error);
        });
});
