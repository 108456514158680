import { useEffect } from "react";
import "./AButton.scss";
interface Props {
  children?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  skin?: "primary" | "light";
  size?: "normal" | "small";
  style?: React.CSSProperties;
}

export const AButton: React.FC<Props> = ({
  children,
  onClick,
  disabled,
  skin,
  size,
  style,
}) => {
  const getClassName = () => {
    let className = "ab-base ";
    switch (skin) {
      case "light":
        className += "light ";
        break;
      case "primary":
      default:
        className += "primary ";
        break;
    }
    switch (size) {
      case "small":
        className += "small ";
        break;
    }
    return className;
  };
  return (
    <div className="landing-button">
      <button
        style={{ ...style, opacity: disabled ? 0.5 : 1 }}
        disabled={disabled || false}
        className={getClassName()}
        onClick={onClick}
      >
        {children}
      </button>
    </div>
  );
};
