import "./Slogen.scss";

export const Slogen = () => {
  return (
    <div className="slogen-main-container">
      <div className="small-container">
        <div className="small-inner">
          <div className="blur-1">ENGAGE</div>
          <div className="normal">REWARD</div>
          <div className="blur-2">CREATE</div>
        </div>
      </div>
      <div className="main-text">
        YOUR
        <br />
        COMMUNITY
      </div>
    </div>
  );
};
