import { useEffect } from "react";
import { useFirebase } from "../Vault/Services/FirebaseService/FirebaseService";
import { TwitterSocialService } from "../../services/TwitterSocialService/TwitterSocialService";
import { TwitterProfileSection } from "./Components/TwitterProfileSection/TwitterProfileSection";
import "./Social.scss";

export const SocialPage = () => {
  useEffect(() => {}, []);

  return (
    <TwitterSocialService>
      <div className="main-social-container">
        <TwitterProfileSection />
      </div>
    </TwitterSocialService>
  );
};
