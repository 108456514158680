/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, createContext, useEffect, useState } from "react";
import { ReactDimmer } from "react-dimmer";
import "./UnstakeModalService.scss";
import { Spin as Hamburger } from "hamburger-react";
import { TokenData, useVault } from "../VaultService/VaultService";
import tickSVG from "../../../../assets/images/tick.svg";
import { AUnstakingButton } from "../../ReusableConponants/AUnstakingButton/AUnstakingButton";
import { parseName } from "../../../../utils/Web3Actions";

const modalContainer: any = {
  width: "100vw",
  height: "100vh",
  position: "fixed",
  zIndex: 50,
  display: "block",
  top: "0px",
  left: "0px",
  overflow: "auto",
};

export type UnstakeModalData = {
  onUnstackSelected?: any;
  disableForm?: any;
};

export const UnstakeModalContext = createContext<UnstakeModalData>({});

export const useUnstakeModal = () => {
  return useContext(UnstakeModalContext);
};

export const UnstakeModalProvider = ({ children, stakingProjectId }: any) => {
  const [dimmer, setDimmer] = useState<boolean>(false);

  useEffect(() => {
    document.body.style.overflowY = dimmer ? "hidden" : "auto";
  }, [dimmer]);

  const onBackdropSelected = (action: boolean) => {
    setDimmer(action);
  };

  const onUnstackSelected = async () => {
    setDimmer(true);
  };

  const vaule: UnstakeModalData = {
    onUnstackSelected,
  };

  return (
    <UnstakeModalContext.Provider value={vaule}>
      {children}
      <ReactDimmer
        zIndex={4}
        opacity={0.8}
        blur={15}
        transition={0.2}
        isOpen={dimmer}
        exitDimmer={onBackdropSelected}
      />
      {dimmer && (
        <div style={modalContainer}>
          <StakeForm closeModal={() => setDimmer(false)} />
          <div className="close-overlay-btn">
            <Hamburger
              hideOutline={true}
              color="#ffdd02"
              toggled={dimmer}
              toggle={setDimmer}
            />
          </div>
        </div>
      )}
    </UnstakeModalContext.Provider>
  );
};

const MAX = 500;

type ItemSelect = {
  token: TokenData;
  selected: boolean;
};

const StakeForm = ({ closeModal }: any) => {
  const { busyStakingTokenAddress, unstakedItems, stakedItems, unstakingDocs } =
    useVault();

  const [items, setItems] = useState<ItemSelect[]>([]);
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    console.log("!@#$%^&*()(*&^%$#@", { unstakingDocs });

    if (unstakingDocs) {
      const parseItems = stakedItems
        ?.filter(
          (doc) =>
            doc.staked &&
            (doc.StakingProgram === "unstake" ||
              doc.StakingProgram === "non-custodial")
        )
        ?.filter((doc) => unstakingDocs.includes(doc.DocId) === false)
        .filter(
          (item) =>
            busyStakingTokenAddress!.includes(item.mintAddress) === false
        )
        .map((item) => ({
          token: item,
          selected: false,
        }));
      setItems([...(parseItems || [])]);
    }
  }, [busyStakingTokenAddress, unstakedItems, unstakingDocs]);

  const setItem = (index: number) => {
    if (isBusy === false) {
      const arr: ItemSelect[] = [...items];
      // arr[index].selected = !arr[index].selected;
      arr[index].selected =
        arr[index].selected === true ? false : checkIsMaxed() ? false : true;
      setItems(arr);
    }
  };

  const getSelected = () =>
    items
      .filter((item) => item.selected && item.token.DocId)
      .map((item) => item.token.DocId!);
  const checkIsMaxed = () =>
    items.filter((i) => i.selected === true).length >= MAX;

  if (unstakingDocs === undefined) {
    return <></>;
  }

  const getNumberFromName = (text: string) => {
    return parseName(text || "") || "";
  };

  return (
    <>
      <div className="vault-stake-container">
        <>
          <div className="title">UNSTAKE ITEMS</div>
          {items.length > 0 && (
            <>
              <div className="explainer">
                You can select up to {MAX} items at once
              </div>
            </>
          )}
          {items.length == 0 && (
            <>
              <div className="no-items">
                You don't have any items available for unstaking...
              </div>
            </>
          )}

          <div
            style={{
              opacity: isBusy ? 0.6 : 1,
            }}
            className="form-container"
          >
            {items.length > 0 &&
              items.map((i, index) => {
                return (
                  <div
                    onClick={() => setItem(index)}
                    className="form-item-container"
                  >
                    <div className="form-title">
                      {getNumberFromName(i.token.tokenData.name)}
                    </div>
                    <div className="form-item-inner-container">
                      <div
                        className={
                          i.selected === false
                            ? "form-item"
                            : "form-item-selected"
                        }
                        style={{
                          opacity: checkIsMaxed() ? 0.3 : 1,
                          background: `url('${i.token.tokenData.image}') center center`,
                          backgroundSize: "contain",
                        }}
                      >
                        {i.selected === true && (
                          <div className="selected-container noselect">
                            <img className="tick" src={tickSVG} alt="" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </>
        <AUnstakingButton
          endpoint="V6_requestHandleUnstakeQueueRequest"
          closeModal={closeModal}
          handleBusy={setIsBusy}
          stakeDocIds={getSelected()}
        />
      </div>
    </>
  );
};
