import { useEffect, useState } from "react";
import "./navbar.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { Spin as Hamburger } from "hamburger-react";
import dvIcon from "../../assets/dv-fav.svg";
import { useScreenSizeService } from "../../pages/Vault/Services/ScreenSizeService/ScreenSizeService";
const BREAK_POINT = 800;
const routes = [
  {
    text: "Home",
    route: "/",
  },
  // {
  //   text: 'Shop',
  //   route: '/shop',
  // },
  // {
  //   text: 'My Vault',
  //   route: '/portfolio',
  // },
  // {
  //     text: 'Evolve',
  //     route: '/evolve'
  // },
  // {
  //   text: 'Vault',
  //   route: '/vault',
  // },
];

const Navbar = () => {
  const { screenWidth } = useScreenSizeService();
  const [toggleMenu, setToggleMenu] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    document.body.style.overflowY = toggleMenu ? "hidden" : "auto";
  }, [toggleMenu]);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  return (
    <nav>
      <div className="logos-container">
        {/* <img
          onClick={() => navigate('/')}
          alt="fav"
          src={dvIcon}
          className="anybodies-logo"
        /> */}
        <div className="dv-logo" onClick={() => navigate("/")}>
          <span className="diamond">DIAMOND</span>
          <span className="vualts">VAULT</span>
        </div>
      </div>

      {screenWidth > BREAK_POINT && (
        <ul className="list">
          {routes.slice(1).map((route) => (
            <NavLink
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              to={route.route}
              className={({ isActive }) =>
                !isActive ? "items" : "items-selected"
              }
            >
              {route.text}
            </NavLink>
          ))}
          {/* <WalletMultiButton className="wallet-btn" /> */}
        </ul>
      )}
      {screenWidth <= BREAK_POINT && (
        <>
          <div></div>
          {toggleMenu && (
            <div className="mobile-menu-container">
              <div onClick={toggleNav} className="menu-item-container">
                {toggleMenu &&
                  routes.map((route) => (
                    <NavLink
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                      to={route.route}
                      className={({ isActive }) =>
                        !isActive ? "items" : "items-selected"
                      }
                    >
                      {route.text}
                    </NavLink>
                  ))}
              </div>
            </div>
          )}
        </>
      )}

      {screenWidth <= BREAK_POINT && (
        <div style={{ zIndex: 3, marginRight: "20px" }}>
          {/* <Hamburger
            hideOutline={true}
            color="#62A770"
            toggled={toggleMenu}
            toggle={toggleNav}
          /> */}
        </div>
      )}
      {/* <div onClick={toggleNav} style={{ "zIndex": 10 }} id="nav-icon4" className={toggleMenu ? 'open' : ''}>
                <span></span>
                <span></span>
                <span></span>
            </div> */}
      {/* <button onClick={toggleNav} className="menu-toggle-btn">BTN</button> */}
    </nav>
  );
};

export default Navbar;
