import "./VaultToolbar.scss";
import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useWallet } from "@solana/wallet-adapter-react";
import moment from "moment";
import { Transaction } from "@solana/web3.js";
import { useFirebase } from "../../Services/FirebaseService/FirebaseService";
import { createLocalSolTransaction } from "../../../../utils/Web3Actions/SolTransfer";

import { AButton } from "../../ReusableConponants/AButton/AButton";
import { tryConfirmTransactionOfficial } from "../../../../utils/Web3Actions";
import {
  SerializedTransaction,
  serializeTransaction,
} from "../../ReusableConponants/AStakingButton/StakingButtontUtils";
import { useStakeModal } from "../../Services/StakeModalService/StakeModalService";
import { useUnstakeModal } from "../../Services/UnstakeModalService/UnstakeModalService";
import { useVault } from "../../Services/VaultService/VaultService";
import { Box } from "@mui/material";


export const VaultToolbar: React.FC<any> = () => {
  const { onStackSelected } = useStakeModal();
  const { onUnstackSelected } = useUnstakeModal();

  const [loading, setLoading] = useState(true);
  const [active, setActive] = useState(false);
  const { firestore, customFirebaseRequest } = useFirebase();
  const {
    vaultId,
    autostakingConfigDoc,
    stakingProjectData,
    vaultConfig: { ClientRPC },
  } = useVault();

  const { addToast } = useToasts();
  const { publicKey, signTransaction } = useWallet();
  const handleStakeRequest = () => {
    onStackSelected();
  };


  const signAndReturnTransaction = (
    transaction: Transaction,
    attempt = 0,
    maxAttempts = 1
  ) =>
    new Promise<Transaction | undefined>(async (resolve) => {
      if (attempt >= maxAttempts) return resolve(undefined);
      try {
        const signedTransation = await signTransaction!(
          transaction
        );
        resolve(signedTransation);
        return signedTransation;
      } catch (err) {
        console.log(err);
        resolve(await signAndReturnTransaction(transaction, attempt + 1));
      }
    });

  const createAutostakongRequestRequest = (
    projectId: string,
    vaultId: string,
    serializedTransaction: SerializedTransaction,
    txid: string
  ) =>
    new Promise<any>((resolve) => {
      customFirebaseRequest("V5User_processAutostakingRequestForVault", {
        projectId,
        vaultId,
        walletAddress: publicKey?.toString(),
        transactionRequestPayload: serializedTransaction,
        txid,
      })
        .then((response) => {
          // handle success
          // console.log("response", response.data);
          resolve(response.data);
        })
        .catch((error: any) => {
          console.log("V5User_processAutostakingRequestForVault err", error);

          // handle error
          // console.log(error);
          resolve(false);
        });
    });

  useEffect(() => {
    let unsubscribe;

    if (publicKey && vaultId && stakingProjectData) {
      // console.log(`/Vault/${vaultId}/WalletAutostakeStatus/${publicKey.toString()}`);

      unsubscribe = firestore
        .doc(
          `/AVaultProject/${
            stakingProjectData.ProjectId
          }/AVault/${vaultId}/AAutoStakingWalletStatus/${publicKey.toString()}`
        )
        .onSnapshot((doc) => {
          // console.log(doc, doc.exists, doc.data());
          if (doc.exists && doc.data()) {
            const { AutostakingActive } = doc.data()!;
            // console.log(IsAutostakeActive);
            setActive(AutostakingActive);
          } else {
            setActive(false);
          }
          setLoading(false);
        });
    }
    return unsubscribe;
  }, [publicKey, vaultId]);

  // if (vualtConfig.disableToolbar)
  //   return (
  //     <div className="toolbat-alert-container" style={{ background: vualtConfig.alertMessageBGColor }}>
  //       {vualtConfig.alertMessage}
  //     </div>
  //   );

  return (
    <>
      {vaultId && (
        <div className="vault-toolbar-container">
          <div className="auto-staking-container">
            <div className="action-container">
              <AButton onClick={handleStakeRequest} size="small" skin="primary">
                STAKE
              </AButton>
              <AButton
                onClick={onUnstackSelected}
                size="small"
                skin="light"
                style={{ marginLeft: "10px" }}
              >
                UNSTAKE
              </AButton>
            </div>
          </div>
         
        </div>
      )}
    </>
  );
};
