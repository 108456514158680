import "./SelectStakingPlan.scss";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useVault } from "../../Services/VaultService/VaultService";

export const SelectStakingPlan: React.FC<any> = ({ onSelectedPlan }) => {
  const { stakingPlans } = useVault();

  return (
    <>
      <div className="select-plan-main-container">
        {stakingPlans?.map((plan) => {
          return <StakingPlan plan={plan} onSelectedPlan={onSelectedPlan} />;
        })}
      </div>
    </>
  );
};

export const StakingPlan: React.FC<any> = ({
  onSelectedPlan,
  plan,
  onChangePlan,
  isBusy,
}) => {
  const getStakingPlan = (RewardProgram: string) => {
    switch (RewardProgram) {
      case "duration":
        return "LOCKED";
      case "non-custodial":
        return "NON-CUSTODIAL";
      case "unstake":
        return "NO LOCK";
      default:
        return "N/A";
    }
  };
  const {
    PlanTitle,
    PlanDescription,
    StakingDuration,
    StakingProgram,
    TokenData: { TokenName },
  } = plan;
  return (
    <>
      <div className="plan-container">
        <div>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid style={{ width: "100%" }} item xs>
                <Typography
                  className="line-break"
                  gutterBottom
                  variant="subtitle1"
                  component="div"
                >
                  {PlanTitle}
                </Typography>
                <Typography className="line-break" variant="body2" gutterBottom>
                  {PlanDescription}
                </Typography>
                <Typography
                  style={{ marginTop: "20px" }}
                  variant="subtitle1"
                  component="div"
                >
                  <Chip label={`$${TokenName}`} color="success" />
                </Typography>
                <Stack
                  style={{ marginTop: "10px" }}
                  direction="row"
                  spacing={1}
                >
                  <Chip label={`${StakingDuration} days`} color="secondary" />
                  <Chip
                    label={getStakingPlan(StakingProgram)}
                    variant={
                      StakingProgram === "duration" ? "outlined" : "filled"
                    }
                    color={
                      StakingProgram === "duration" ? "warning" : "primary"
                    }
                  />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </div>
        {onSelectedPlan && (
          <Button
            onClick={() => onSelectedPlan(plan)}
            style={{ marginTop: "30px", color: "#eccd0e" }}
            size="large"
            color="inherit"
          >
            SELECT
          </Button>
        )}
        {onChangePlan && (
          <Button
            disabled={isBusy}
            onClick={() => onChangePlan(plan)}
            style={{ marginTop: "30px", color: "#eccd0e" }}
            size="large"
            color="inherit"
          >
            CHANGE
          </Button>
        )}
      </div>
    </>
  );
};
