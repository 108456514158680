// import './App.css';
// import { useMemo } from 'react';
// import * as anchor from '@project-serum/anchor';
// import Home from './Home';

// import { clusterApiUrl } from '@solana/web3.js';
// import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
// import {
//   getPhantomWallet,
//   getSlopeWallet,
//   getSolflareWallet,
//   getSolletWallet,
//   getSolletExtensionWallet,
// } from '@solana/wallet-adapter-wallets';

// import {
//   ConnectionProvider,
//   WalletProvider,
// } from '@solana/wallet-adapter-react';

// import { ThemeProvider, createTheme } from '@material-ui/core';

// const theme = createTheme({
//   palette: {
//     type: 'dark',
//   },
// });

// const getCandyMachineId = (): anchor.web3.PublicKey | undefined => {
//   try {
//     const candyMachineId = new anchor.web3.PublicKey(
//       process.env.REACT_APP_CANDY_MACHINE_ID!,
//     );

//     return candyMachineId;
//   } catch (e) {
//     console.log('Failed to construct CandyMachineId', e);
//     return undefined;
//   }
// };

// const candyMachineId = getCandyMachineId();
// const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;
// const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
// const connection = new anchor.web3.Connection(
//   rpcHost ? rpcHost : anchor.web3.clusterApiUrl('devnet'),
// );

// const txTimeoutInMilliseconds = 30000;

// const App = () => {
//   const endpoint = useMemo(() => clusterApiUrl(network), []);

//   const wallets = useMemo(
//     () => [
//       getPhantomWallet(),
//       getSolflareWallet(),
//       getSlopeWallet(),
//       getSolletWallet({ network }),
//       getSolletExtensionWallet({ network }),
//     ],
//     [],
//   );

//   return (
//     <ThemeProvider theme={theme}>
//       <ConnectionProvider endpoint={endpoint}>
//         <WalletProvider wallets={wallets} autoConnect={false}>
//           <WalletDialogProvider>
//           <WalletMultiButton></WalletMultiButton>
//           </WalletDialogProvider>
//         </WalletProvider>
//       </ConnectionProvider>
//     </ThemeProvider>
//   );
// };

// export default App;

import "./App.scss";

import { Route, Link, Routes, Navigate } from "react-router-dom";
import { Vault } from "./pages/Vault/Vault";
import { Home } from "./pages/Home/Home";
import { VerifyPage } from "./pages/Verify/Verify";
import Navbar from "./components/navbar/navbar";
import { Portfolio } from "./pages/Portfolio/Portfolio";
import { Terms } from "./pages/Terms/Terms";
import { Privacy } from "./pages/Terms/Privacy";
import { SocialPage } from "./pages/Social/Social";
import { LandingPage } from "./pages/LandingPage/LandingPage";
import { createTheme, ThemeProvider } from "@mui/material";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

export function App() {
  return (
    <>
      <ThemeProvider theme={darkTheme}>
        {/* <Routes>
        <Route path="/" element={<Navbar />} />
        <Route path="/portfolio" element={<Navbar />} />
      </Routes> */}
        <Routes>
          <Route path="/" element={<LandingPage />} />
          {/* <Route path="/portfolio" element={<Portfolio />} /> */}
          <Route path="/verify" element={<VerifyPage />} />
          <Route path="/vault/:id" element={<Vault />} />
          <Route path="/social/:id" element={<SocialPage />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/vault/:id" element={<Vault />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </ThemeProvider>
    </>
  );
}

export default App;
